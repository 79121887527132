import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const hubspotApi = createApi({
    reducerPath: 'hubspotApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/hubspot',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getContacts: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/contacts`,
                    params: {
                        limit: 10,
                        archived: false
                    }
                };
            }
        }),
        initHubspot: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/`
                };
            }
        }),
        oauthHubspot: builder.query({
            query: ({ code }) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/oauth-callback`,
                    params: { code }
                };
            }
        }),
        createEmail: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: '/create-email',
                body: payload
            })
        }),
        hubspotUserInfo: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/info`
                };
            }
        }),
        hubspotLogout: builder.mutation({
            query: () => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/logout`
                };
            }
        })
    })
});

export const {
    useLazyGetContactsQuery,
    useLazyInitHubspotQuery,
    useLazyOauthHubspotQuery,
    useLazyHubspotUserInfoQuery,
    useCreateEmailMutation,
    useHubspotLogoutMutation
} = hubspotApi;
