export const ANALYTICS = {
    SCREEN_NAMES: {
        LOGIN: 'login',
        SIGNUP: 'signup',
        CONTENT_SELECTION: 'content_selection',
        RESULTS_VIEW: 'results_view',
        DOC_CREATER_VIEW: 'doc_creator_view',
        USER_PROFILE: 'user_profile',
        BDR_CADENCE_CONTENT_SELECTION: 'bdr_cadence_content_selection'
    },
    ACTIONS: {
        LOGIN_CLICK: 'login_click',
        SIGNUP_CLICK: 'signup_click',
        GOOGLE_LOGIN_CLICK: 'google_login_click',
        BACK_CLICK: 'back_click',
        NEXT_CLICK: 'next_click',
        REGENERATE_CLICK: 'regenerate_click',
        START_AGAIN: 'start_again',
        DOWNLOAD_DOC_CLICK: 'download_doc_click',
        WORD_COUNT_CHANGE: 'word_count_change',
        CANCEL_EDIT: 'cancel_edit',
        EDIT_CHOICE: 'edit_choice',
        SAVE_EDIT: 'save_edit',
        FILE_UPLOAD: 'file_upload',
        LOGOUT: 'logout',
        SELECT_SOURCE_TYPE: 'select_source_type',
        CONTENT_TYPE: 'content_type',
        SELECT_TONE_TYPE: 'select_tone_type',
        SELECT_OUTLOOK_TYPE: 'select_outlook_type',
        SELECT_AUDIENCE_TYPE: 'select_audience_type',
        SELECT_PERSONA_TYPE: 'select_persona_type',
        LIKE_CLICK: 'like_click',
        DISLIKE_CLICK: 'dislike_click'
    }
};
