import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const campaignsApi = createApi({
    reducerPath: 'campaignsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/campaign',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getUserCampaigns: builder.query({
            query: (payload) => {
                const { page = 1, limit = 10, filter = 0 } = payload;
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: '/all',
                    params: { page, limit, filter }
                };
            }
        }),
        getCampaign: builder.query({
            query: (payload) => {
                const { id } = payload;
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        }),
        addCampaign: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/add`,
                    body: payload
                };
            }
        }),
        updateCampaign: builder.mutation({
            query: (payload) => {
                const { id, data } = payload;
                return {
                    method: 'PATCH',
                    headers: { ...headers },
                    url: `/${id}`,
                    body: data
                };
            }
        }),
        deleteCampaign: builder.query({
            query: (payload) => {
                const { id } = payload;
                return {
                    method: 'Delete',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        })
    })
});

export const {
    useLazyGetUserCampaignsQuery,
    useLazyGetCampaignQuery,
    useLazyDeleteCampaignQuery,
    useAddCampaignMutation,
    useUpdateCampaignMutation
} = campaignsApi;
