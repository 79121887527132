//hook
import { useDispatch } from 'react-redux';

//api
import { useGetResultsMutation } from 'src/redux/queries';

//helpers
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from 'src/redux/reducers';

const useGenerateTags = () => {
    const dispatch = useDispatch();

    const [getResults] = useGetResultsMutation();

    const generateTags = async (content) => {

        // move this to config
        const prompt = `Generate relevant 10-15 keyword tags of max 20 character length from the following content base on what kind of problem we are solving and how is it being solved, in what tone the content is : ${JSON.stringify(
            content
        )}`;

        try {
            dispatch(startLoading());
            const result = await getResults({ message: prompt, mType: 1, count: 1 }).unwrap();
            let choice = result?.choices[0];
            if (choice.length) {
                const items = choice.split('\n').map((item) => item.trim());
                // Remove the word count (e.g., "1.", "2.", etc.) from each item
                const topics = items.map((item) => item.replace(/^\d+\.\s*/, ''));
                return topics;
            }
            return [];
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    return generateTags;
};

export default useGenerateTags;
