import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const embeddingsApis = createApi({
    reducerPath: 'embeddingsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/embeddings',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        generateEmbeddings: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: '/generateEmbeddings',
                    body: payload
                };
            }
        })
    })
});

export const { useGenerateEmbeddingsMutation } = embeddingsApis;
