//libs
import React from 'react';
import cx from 'classnames';
import { generatePath } from 'react-router-dom';

//styles
import styles from './AddCampaignTypeModal.module.scss';

//constants
import { ROUTES } from 'src/shared/constants';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//assets
import Icomoon from 'src/assets/Icomoon';

//widgets
import BetaTag from 'src/components/BetaTag';

interface IProps {
    handleClose: VoidFunction;
}

const AddCampaignTypeModal = ({ handleClose }: IProps) => {
    const handleNavigation = useCustomNavigation();

    const goToCampaign = () => {
        handleClose();
        handleNavigation(ROUTES.CAMPAIGN, false, {
            new: true
        });
    };

    const goToBdrCampaign = () => {
        const path = generatePath(ROUTES.CAMPAIGN_BDR_CADENCE);
        handleClose();
        handleNavigation(path, false, {
            new: true
        });
    };

    const goToPersonalizedCampaign = () => {
        const path = generatePath(ROUTES.CAMPAIGN_PESONALIZED);
        handleClose();
        handleNavigation(path, false, {
            new: true
        });
    };

    const blockStyle = cx(
        'flex items-center justify-center',
        'p-2 shadow-md rounded',
        'w-full my-2',
        'bg-black text-white text-sm',
        'cursor-pointer'
    );

    const CampaignCtas = ({
        title,
        onClick,
        idx
    }: {
        title: string;
        onClick: VoidFunction;
        idx: number;
    }) => {
        return (
            <div className={cx(blockStyle, styles.cta)} onClick={onClick}>
                {title}

                {idx === 2 && <BetaTag classname={styles.tag} />}
            </div>
        );
    };

    const CAMPAIGN_TYPES = [
        { title: 'Universal Campaign', onClick: goToCampaign },
        { title: 'BDR Cadence Campaign', onClick: goToBdrCampaign },
        { title: 'Personalized Campaign', onClick: goToPersonalizedCampaign }
    ];

    return (
        <div className={cx(styles.overlay)}>
            <div className={cx(styles.container, 'p-2 shadow-lg bg-gray-100 rounded-lg')}>
                <Icomoon className={styles.closeButton} onClick={handleClose} icon={'add'} />
                <div className={cx('py-1 px-2', 'text-md font-medium')}>
                    {'Select Campaign type'}
                </div>
                <div
                    className={cx(
                        'flex flex-col items-center justify-around',
                        'rounded-lg font-medium',
                        'mt-2 px-2'
                    )}
                >
                    {CAMPAIGN_TYPES.map((campaign, key) => (
                        <CampaignCtas
                            idx={key}
                            key={key}
                            title={campaign.title}
                            onClick={campaign.onClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddCampaignTypeModal);
