import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//assets
import Icomoon from 'src/assets/Icomoon';
import Tooltip from 'src/components/Tooltip';

const RadioButton = ({
    className = '',
    data = { label: '', value: '' },
    onChange = () => {},
    checked = false,
    id = '1',
    inputClass = '',
    showLabel = true,
    showTooltip = false
}) => {
    const handleChange = (e) => {
        e?.target?.blur();
        onChange(e);
    };

    return (
        <div className={cx(styles.container, className)}>
            <input
                className={cx(styles.containerInput, inputClass)}
                type="radio"
                id={id}
                onChange={handleChange}
                checked={checked}
                style={{ background: checked ?? 'green' }}
            />
            <label className={styles.containerLabel} htmlFor={id}>
                {!!data?.icon && <Icomoon className={styles.icons} icon={data.icon} />}
                {showLabel && (data.label || data.name)}
            </label>
            {showTooltip && <Tooltip title={''} tooltipText={data.tooltip} />}
        </div>
    );
};

export default React.memo(RadioButton);
