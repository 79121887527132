//lib
import React from 'react';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

//styles
import styles from './styles.module.scss';

const dimensions = '40%';

export const LoaderHoc = ({ children }) => {
    const isLoading = useSelector((state) => state.root.isLoading);

    const renderLoader = () => {
        return (
            <div className={styles.container}>
                <div className={styles.loader}>
                    <Bars
                        height={dimensions}
                        width={dimensions}
                        color="#004170"
                        ariaLabel="loading"
                        visible={true}
                        wrapperClass={styles.loader}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {children}
            {!!isLoading && renderLoader()}
        </>
    );
};
