import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//constants
import { STRINGS } from 'src/shared/strings';

//icons
import { ICON_EYE } from 'src/shared/constants/icons';

//widgets
import { ColorRing } from 'react-loader-spinner';

//apis
import { useAddContentMutation, useLazyFetchContentQuery } from 'src/redux/queries/Org';

//defs
import { IOrg } from 'src/defs';

interface IProps {
    org: IOrg;
}

const ContentList = ({ org }: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState([]);

    const [fetchContent] = useLazyFetchContentQuery();
    const [addConent] = useAddContentMutation();

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const contentData = await fetchContent(org.code).unwrap();
            setContent(contentData.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const tableHeadingsStyle = 'p-1';
    const tableRowStyle = 'flex items-center p-1 h-8';
    const col1 = 'w-12';
    const col2 = 'w-20';
    const col3 = 'w-2/5';

    const renderContentData = () => {
        if (!content?.length)
            return <div className="text-center pt-20 h-40 font-bold">No content found!</div>;

        return (
            <div className={cx('px-8 mt-2')}>
                <div
                    className={cx(
                        'flex items-center',
                        'text-lg font-bold',
                        'border-stone-500 border-b-2'
                    )}
                >
                    <div className={cx(col1, tableHeadingsStyle)}>SR.</div>
                    <div className={cx(col2, tableHeadingsStyle)}>Type</div>
                    <div className={cx(col3, tableHeadingsStyle)}>File Name</div>
                    <div className={cx(col3, tableHeadingsStyle)}>Updated By</div>
                    {/* <div className={cx(col2, tableHeadingsStyle)}>Actions</div> */}
                </div>

                <div className={cx('')}>
                    {content.map(
                        (
                            data: {
                                fileData: { name: string };
                                createdBy: string;
                                updatedBy: string;
                            },
                            key
                        ) => {
                            let fileName = decodeURI(data?.fileData?.name);
                            fileName =
                                fileName?.length > 50 ? `${fileName.slice(0, 50)}...` : fileName;
                            return (
                                <div
                                    key={key}
                                    className={cx(
                                        'flex items-center',
                                        'border-stone-300 border-b-2',
                                        key % 2 === 0 ? 'bg-slate-300' : 'bg-white'
                                    )}
                                >
                                    <div className={cx(col1, tableRowStyle)}>{key + 1}</div>
                                    <div className={cx(col2, tableRowStyle)}>{'PDF'}</div>
                                    <div className={cx(col3, tableRowStyle)}>{fileName}</div>
                                    <div className={cx(col3, tableRowStyle)}>
                                        {data?.updatedBy || data?.createdBy}
                                    </div>
                                    {/* <div className={cx(ICON_EYE, col2, tableRowStyle)}></div> */}
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className={cx('h-40 p-4 flex flex-col items-center justify-center')}>
                <ColorRing
                    height={40}
                    width={40}
                    colors={['#004170', '#004170', '#004170', '#004170', '#004170']}
                    ariaLabel="loading"
                    visible={true}
                    wrapperClass={cx()}
                />
                <p className={'text-neutral-600'}>{'Fetching content ....'}</p>
            </div>
        );
    };

    return (
        <div className={cx('p-4')}>
            <h5 className="block mb-0 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                {STRINGS.ORG_CONTENT}
            </h5>

            {isLoading ? renderLoader() : renderContentData()}
        </div>
    );
};

export default React.memo(ContentList);
