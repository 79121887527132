//libs
import React, { useEffect, useRef, useState } from 'react';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import HardBreak from '@tiptap/extension-hard-break';
import Highlight from '@tiptap/extension-highlight';
import cx from 'classnames';

//styles
import './styles.scss';

//widgets
import Commands from './Command';
import SuggestionItems from './SuggestionItems';
import RenderSuggestionItems from './RenderSuggestionItems';
import { CustomInput } from 'src/widgets';

//hook
import useRephrase from 'src/hooks/useRephrase';
import { useSelector } from 'react-redux';

//constants
import { STRINGS } from 'src/shared/strings';

function TextArea({ content, onChange, noClap }) {
    const getRephrasedText = useRephrase();
    const isLoading = useSelector((state) => state.root.isLoading);

    const [instructions, setInstructions] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            HardBreak,
            Highlight,
            Placeholder.configure({
                placeholder: 'Use / for AI'
            }),
            Commands.configure({
                suggestion: {
                    items: SuggestionItems,
                    render: RenderSuggestionItems
                }
            })
        ],
        content,
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        }
    });

    useEffect(() => {
        if (content !== editor?.getHTML()) {
            editor?.commands.setContent(content);
        }
    }, [content, editor]);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.onfocus = () => {
                editor?.commands?.setHighlight({ color: 'ffcc00' });
            };

            inputRef.current.onblur = () => {
                editor?.commands?.unsetHighlight();
            };
        }
    }, [inputRef, editor]);

    const highlightMenuItem = (active) => {
        return 'px-2 py-1 rounded bg-black text-white hover:shadow';
    };

    const handleInstructionsChange = (e) => {
        setError('');
        const { value } = e?.target;
        setInstructions(value);
    };

    const onRephrase = async () => {
        if (!instructions) {
            setError('Empty instructions');
            return;
        }

        const currentSelectionIsEmpty = editor.view.state.selection.empty;

        if (!currentSelectionIsEmpty) {
            const from = editor.view.state.selection.$head.pos;
            const to = editor.view.state.selection.$anchor.pos;

            const highlightedText = editor.state.doc.textBetween(to, from);
            const paragraph = editor.getHTML();

            let rephrasedText = await getRephrasedText(highlightedText, paragraph, instructions);
            editor.commands.setContent(rephrasedText);
            onChange(rephrasedText);
            setInstructions('');
        }
    };

    return (
        <div className="flex flex-row min-h-min justify-center items-center mb-10">
            <div className="rounded-xl shadow border w-[50%] bg-white w-full">
                {editor && !noClap && (
                    <BubbleMenu
                        editor={editor}
                        tippyOptions={{ duration: 100 }}
                        className={cx('bg-white rounded-xl border p-3 shadow w-[30rem]')}
                    >
                        {!isLoading && (
                            <div className={cx('bg-white', 'rounded-xl', 'w-[28rem]')}>
                                <CustomInput
                                    inputType="textarea"
                                    inputClass={cx('resize-none')}
                                    customCss={{ padding: 0, border: 0 }}
                                    value={instructions}
                                    onChange={handleInstructionsChange}
                                    error={error}
                                    label={'Enter instructions for the selected text'}
                                    inputRef={inputRef}
                                />
                                <div className={cx('flex justify-end', 'mt-2')}>
                                    <button
                                        onClick={onRephrase}
                                        className={highlightMenuItem(editor.isActive('bold'))}
                                    >
                                        {STRINGS.INSTRUCT}
                                    </button>
                                </div>
                            </div>
                        )}
                    </BubbleMenu>
                )}
                <EditorContent editor={editor} />

                {!noClap && (
                    <p className="p-3 text-right text-sm italic text-gray-400 font-medium">
                        {"Highlight the text for paraphrasing; use '/' for commands."}
                    </p>
                )}
            </div>
        </div>
    );
}

export default React.memo(TextArea);
