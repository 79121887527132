import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

//router
import { BrowserRouter as Router } from 'react-router-dom';

//routes
import PrivateRoutes from 'src/routes/Private';
import PublicRoutes from 'src/routes/Public';

//analytics
import { analytics } from 'src/shared/utils/Analytics';

const Routes = () => {
    const token = useSelector((state) => state.user.token);
    const userDetails = useSelector((state) => state.user.userDetails);

    useEffect(() => {
        analytics.init();
    }, [userDetails?.id]);

    return <Router>{token ? <PrivateRoutes /> : <PublicRoutes />}</Router>;
};

export default Routes;
