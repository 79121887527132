import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

const CustomButton = ({ className = '', title = '', onClick, id = '', gradient = false }) => {
    return (
        <h1
            id={id}
            className={cx(styles.container, className, { [styles.gradient]: gradient })}
            onClick={onClick}
        >
            {title}
        </h1>
    );
};

export default React.memo(CustomButton);
