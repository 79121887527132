import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

//styles
import 'react-toastify/dist/ReactToastify.css';
import 'src/App.css';

//store
import store, { persistor } from 'src/redux/store';

//routes
import Routes from 'src/routes';

//components
import { LoaderHoc } from 'src/components';

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <LoaderHoc>
                    <div className="appContainer">
                        <Routes />
                    </div>
                    <ToastContainer
                        theme="colored"
                        hideProgressBar={true}
                        position="bottom-center"
                        limit={3}
                    />
                </LoaderHoc>
            </PersistGate>
        </Provider>
    );
};

export default App;
