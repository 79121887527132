import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const ragApis = createApi({
    reducerPath: 'ragApis',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/rag',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        generateBlocks: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: '/generateBlocks',
                    body: payload
                };
            }
        })
    })
});

export const { useGenerateBlocksMutation } = ragApis;
