import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { usersApi } from 'src/redux/queries/User';
import rootreducer from 'src/redux/reducers';
import userReducer from 'src/redux/reducers/userReducer';
import configReducer from 'src/redux/reducers/configReducer';
import taxonomyReducer from 'src/redux/reducers/taxonomy';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import { authApi } from 'src/redux/queries/Auth';
import { configApi } from 'src/redux/queries/config';
import { documentTypesApi } from 'src/redux/queries/documentTypes';
import { personasApi } from 'src/redux/queries/Personas';
import { promptsApi } from 'src/redux/queries/prompts';
import { campaignsApi } from 'src/redux/queries/Campaigns';
import { orgApi } from 'src/redux/queries/Org';
import { hubspotApi } from 'src/redux/queries/Hubspot';
import { audienceApi } from 'src/redux/queries/Audience';
import { linkedinApi } from 'src/redux/queries/Linkedin';
import { ragApis } from 'src/redux/queries/Rag';
import { embeddingsApis } from 'src/redux/queries/Embeddings';
import { contentApi } from 'src/redux/queries/Content';
import { searchApis } from 'src/redux/queries/Search';
import { clapApi } from 'src/redux/queries/Clap';

const middlewares = [
    authApi.middleware,
    usersApi.middleware,
    configApi.middleware,
    promptsApi.middleware,
    personasApi.middleware,
    documentTypesApi.middleware,
    campaignsApi.middleware,
    orgApi.middleware,
    hubspotApi.middleware,
    audienceApi.middleware,
    linkedinApi.middleware,
    ragApis.middleware,
    embeddingsApis.middleware,
    contentApi.middleware,
    searchApis.middleware,
    clapApi.middleware
];

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'authApi',
        'usersApi',
        'root',
        'configApi',
        'promptsApi',
        'personasApi',
        'documentTypesApi',
        'campaignsApi',
        'orgApi',
        'hubspotApi',
        'audienceApi',
        'linkedinApi',
        'ragApis',
        'embeddingsApis',
        'contentApi',
        'searchApis',
        'clapApi'
    ]
};

const combinedReducer = combineReducers({
    root: rootreducer,
    user: userReducer,
    config: configReducer,
    taxonomy: taxonomyReducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [promptsApi.reducerPath]: promptsApi.reducer,
    [personasApi.reducerPath]: personasApi.reducer,
    [documentTypesApi.reducerPath]: documentTypesApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [hubspotApi.reducerPath]: hubspotApi.reducer,
    [audienceApi.reducerPath]: audienceApi.reducer,
    [linkedinApi.reducerPath]: linkedinApi.reducer,
    [ragApis.reducerPath]: ragApis.reducer,
    [embeddingsApis.reducerPath]: embeddingsApis.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [searchApis.reducerPath]: searchApis.reducer,
    [clapApi.reducerPath]: clapApi.reducer
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(middlewares)
});

export const persistor = persistStore(store);

export default store;
