export const ICON_TAG = 'icon-tag';
export const ICON_THUMBS_DOWN = 'icon-thumbs-down';
export const ICON_THUMBS_UP = 'icon-thumbs-up';
export const ICON_BIN = 'icon-bin';
export const ICON_ADD = 'icon-add';
export const ICON_PENCIL = 'icon-pencil';
export const ICON_EYE = 'icon-eye';
export const ICON_EYE_OFF = 'icon-eye-off';
export const ICON_COMMAND = 'icon-command';
export const ICON_CANCEL = 'icon-cancel';
export const ICON_SEARCH = 'icon-search';
export const ICON_FILTER = 'icon-filter';
export const ICON_HUBSPOT = 'icon-hubspot';
export const ICON_LINKEDIN = 'icon-linkedin';
