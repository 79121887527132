export const validateForm = (
    data = {
        segment: { value: '' },
        targetIndustry: { value: '' },
        title: { value: '' },
        companyProfile: '',
        linkedinProfile: '',
        kpis: ''
    }
) => {
    const { segment, targetIndustry, title, companyProfile, linkedinProfile, kpis } = data;

    const errors = {};

    if (!segment || !segment?.value) {
        errors.segment = `Please select target segment!`;
    }

    if (!targetIndustry || !targetIndustry?.value) {
        errors.targetIndustry = `Please add target industry!`;
    }

    if (!title || !title?.value) {
        errors.title = `Please add title!`;
    }

    if (!companyProfile || !companyProfile?.length) {
        errors.companyProfile = `Please add target company profile!`;
    }

    // if (!linkedinProfile) {
    //     errors.linkedinProfile = `Please add user linkedun profile!`;
    // }

    // if (!kpis || !kpis?.length) {
    //     errors.kpis = `Please add key performance indicators!`;
    // }

    return { ...errors };
};
