import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants';
import { ICON_FILTER, ICON_SEARCH } from 'src/shared/constants/icons';

//helpers
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { generatePath } from 'react-router-dom';

//widgets
import { CustomInput } from 'src/widgets';
import DeleteModal from 'src/components/DeleteModal';
import CampaignCard from 'src/views/Private/Home/widgets/CampaignCard';
import Footer from 'src/components/Footer';

//apis
import {
    useLazyDeleteCampaignQuery,
    useLazyGetUserCampaignsQuery
} from 'src/redux/queries/Campaigns';
import { useLazyDownloadFileQuery } from 'src/redux/queries';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import useClickOutside from 'src/hooks/useClickOutside';
import { decryptPayload } from 'src/shared/utils';
import useUpdateUserDetails from 'src/hooks/useUpdateUserDetails';

const Home = () => {
    const dispatch = useDispatch();
    const [getUserCampaigns] = useLazyGetUserCampaignsQuery();
    const [deleteCampaign] = useLazyDeleteCampaignQuery();
    const [getFile] = useLazyDownloadFileQuery();

    const handleNavigation = useCustomNavigation();
    const updateUser = useUpdateUserDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [recentCampaigns, setRecentCampaigns] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [fileLink, setFileLink] = useState({ link: '', name: '' });
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [query, setQuery] = useState('' || []);
    const [nameQuery, setNameQuery] = useState('');
    const [orgOptions, setOrgOptions] = useState([]);
    const [filteredCamapaigns, setFilteredCampaigns] = useState([]);

    const token = useSelector((state) => state.user.token);
    const userOrgs = useSelector((state) => state.user.userDetails?.orgs || []);

    const handleMenuClickOutside = () => {
        setShowMenu(false);
    };

    const menuRef = useClickOutside(handleMenuClickOutside);

    useEffect(() => {
        if (userOrgs?.length) {
            const options = userOrgs.map((org) => ({
                label: org?.name,
                value: org?.code
            }));
            setOrgOptions([...options]);
        }
    }, [userOrgs]);

    useEffect(() => {
        updateUser();
        document.title = 'Campaign Like a Pro - Home Page';
        fetchUserCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserCampaigns = useCallback(
        async (hideLoader) => {
            dispatch(startLoading());
            !hideLoader && setIsLoading(true);
            await getUserCampaigns({ page: 1, limit: 5, filter: 0 })
                .unwrap()
                .then((data) => setRecentCampaigns(data.data))
                .catch((err) => toast.error(err?.data?.message))
                .finally(() => {
                    dispatch(stopLoading());
                    setIsLoading(false);
                });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const goToCampaign = (id, isBdrCadence, isPersonalized) => {
        const path = generatePath(
            isBdrCadence
                ? ROUTES.CAMPAIGN_BDR_CADENCE_ID
                : isPersonalized
                ? ROUTES.CAMPAIGN_PESONALIZED_ID
                : ROUTES.CAMPAIGN_ID,
            { id }
        );
        handleNavigation(path);
    };

    const handleThreeDotsClick = (e, deleteId, fileData) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setDeleteId(deleteId);
        setFileLink(fileData);
        setMenuPosition({
            position: 'absolute',
            top: rect.bottom + window.scrollY,
            left: rect.left - 130
        });
        setShowMenu(true);
        e.stopPropagation();
    };

    const handleCampaignDelete = async () => {
        if (!deleteId) return;

        try {
            dispatch(startLoading());
            await deleteCampaign({ id: deleteId })
                .unwrap()
                .then((data) => {
                    toast.success(data.message);
                    setShowDeleteModal(false);
                    fetchUserCampaigns(true);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.data?.message);
                });
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderMenuModal = () => {
        if (!deleteId && !fileLink?.link) return;

        return (
            <div ref={menuRef} className={styles.menu} style={menuPosition}>
                {fileLink?.link && (
                    <div className={styles.menuItem} onClick={downloadFile}>
                        {STRINGS.DOWNLOAD_DOC}
                    </div>
                )}
                <div
                    className={styles.menuItem}
                    onClick={() => {
                        setShowDeleteModal(true);
                        handleMenuClickOutside();
                    }}
                >
                    {STRINGS.DELETE}
                </div>
            </div>
        );
    };

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={handleCampaignDelete}
                setIsOpen={setShowDeleteModal}
                title={STRINGS.DELETE_CAMPAIGN}
                desc={STRINGS.DELETE_CAMPAIGN_DESC}
            />
        );
    };

    const downloadFile = () => {
        setShowMenu(false);
        dispatch(startLoading());
        getFile({ file: fileLink.link, name: `Campaign_${fileLink?.name?.replace(' ', '_')}.docx` })
            .unwrap()
            .then((data) => {
                const url = decryptPayload(data, token)?.url;
                window.open(url, '_blank');
            })
            .catch((error) => toast.error(error?.data?.message))
            .finally(() => dispatch(stopLoading()));
    };

    const { userCampaigns, orgCampaigns } = recentCampaigns;

    useEffect(() => {
        if (query?.length) {
            const queryIds = query?.map((q) => `${q.value}`);
            let campaigns = [...userCampaigns, ...orgCampaigns].filter((campaign) =>
                queryIds.includes(campaign?.orgId)
            );
            setFilteredCampaigns([...campaigns]);
        } else {
            setFilteredCampaigns([]);
        }
    }, [query, userCampaigns, orgCampaigns]);

    useEffect(() => {
        if (nameQuery?.length) {
            let campaigns = [...userCampaigns, ...orgCampaigns].filter((campaign) =>
                campaign?.title?.toLowerCase().includes(nameQuery.toLowerCase())
            );
            setFilteredCampaigns([...campaigns]);
        } else {
            setFilteredCampaigns([]);
        }
    }, [nameQuery, userCampaigns, orgCampaigns]);

    const filterActive = !!query.length || !!nameQuery.length;

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{STRINGS.HOME}</h1>

            {!isLoading && (
                <div className={styles.draft}>
                    {userCampaigns?.length || orgCampaigns?.length ? (
                        <div className={styles.draftTable}>
                            <div className={ICON_FILTER} />
                            <div className={styles.query}>
                                <CustomInput
                                    inputType="select"
                                    placement="bottom"
                                    inputProps={{ placeholder: 'Organization' }}
                                    selectOptions={orgOptions}
                                    onChange={(e) => {
                                        setQuery(e.target.value);
                                    }}
                                    className={cx(styles.queryInput)}
                                    value={query}
                                />

                                <CustomInput
                                    inputProps={{ placeholder: 'Campaign Title' }}
                                    onChange={(e) => {
                                        setNameQuery(e.target.value);
                                    }}
                                    className={cx(styles.queryInput)}
                                    value={nameQuery}
                                    icon={ICON_SEARCH}
                                />
                            </div>

                            {filterActive && (
                                <>
                                    <h1 className={cx(styles.draftTitle, 'mt-4')}>
                                        {'Filtered Campaigns'}
                                    </h1>
                                    <div className={cx('flex flex-wrap')}>
                                        {!!filteredCamapaigns?.length ? (
                                            filteredCamapaigns?.map((rec, key) => (
                                                <CampaignCard
                                                    key={key}
                                                    data={rec}
                                                    setShowMenu={setShowMenu}
                                                    setDeleteId={setDeleteId}
                                                    handleThreeDotsClick={handleThreeDotsClick}
                                                    goToCampaign={goToCampaign}
                                                />
                                            ))
                                        ) : (
                                            <div className={styles.empty}>
                                                {'No campaigns found for the selected Org'}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {!filterActive && !!userCampaigns?.length && (
                                <>
                                    <h1 className={cx(styles.draftTitle, 'mt-4')}>
                                        {'Recent Campaigns'}
                                    </h1>
                                    <div className={cx('flex flex-wrap')}>
                                        {userCampaigns?.map((rec, key) => (
                                            <CampaignCard
                                                key={key}
                                                data={rec}
                                                self={true}
                                                setShowMenu={setShowMenu}
                                                setDeleteId={setDeleteId}
                                                handleThreeDotsClick={handleThreeDotsClick}
                                                goToCampaign={goToCampaign}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}

                            {!filterActive && !!orgCampaigns?.length && (
                                <>
                                    <div className={cx(styles.draftTitle, 'mt-4')}>
                                        {'Organization Campaigns'}
                                    </div>
                                    <div className={cx('flex flex-wrap')}>
                                        {orgCampaigns?.map((rec, key) => (
                                            <CampaignCard
                                                key={key}
                                                data={rec}
                                                setShowMenu={setShowMenu}
                                                setDeleteId={setDeleteId}
                                                handleThreeDotsClick={handleThreeDotsClick}
                                                goToCampaign={goToCampaign}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={styles.empty}>
                            {'Revolutionize your outreach strategy. Click + on the left to start.'}
                        </div>
                    )}
                    {showMenu && renderMenuModal()}
                    {showDeletModal && deleteId && renderDeleteModal()}
                </div>
            )}
            <Footer />
        </div>
    );
};

export default React.memo(Home);
