import React, { useRef } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//assets
import Bin from 'src/assets/icons/bin_white.png';
import Bin_Grad from 'src/assets/icons/bin_grad.png';

//constants
import { LABELS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';

//helper
import { toast } from 'react-toastify';
import { sanitizeFileNames } from 'src/shared/utils';

const FileUpload = ({
    handleUpload = (files) => {},
    files = [],
    type = 'pdf',
    multiple = false,
    ctaTitle = '',
    ctaSelectedTitle = '',
    containerClass = '',
    isDisabled = false
}) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        if (isDisabled) return;

        hiddenFileInput.current.click();
    };
    const handleChange = (e) => {
        if (isDisabled) return;

        const selectedFiles = e.target.files;

        if (selectedFiles.length + files.length > 5) {
            toast.error('You can only select up to 5 files.');
            e.target.value = '';
            return;
        }

        let filesArray = Array.from(selectedFiles);
        if (files.length) {
            filesArray = [...files, ...filesArray];
        }
        e.target.value = '';
        handleUpload(filesArray);
    };

    const acceptType =
        type === CONTENT_FILE_TYPES.PDF.value
            ? 'application/pdf, .pdf'
            : type === CONTENT_FILE_TYPES.DOCX.value
            ? '.doc, .docx'
            : '.txt, application/pdf, .pdf, .doc, .docx ';

    const removeFile = (idx) => {
        files.splice(idx, 1);
        handleUpload([...files]);
    };

    return (
        <div className={cx(styles.container, containerClass, 'flex')}>
            <h1
                className={cx(styles.containerButton, {
                    [styles.disabled]: files.length >= 5 || isDisabled
                })}
                onClick={handleClick}
            >
                {files[0]?.name
                    ? ctaSelectedTitle || LABELS.UPLOADED
                    : ctaTitle || LABELS.UPLOAD_FILE}
            </h1>
            <div className={styles.containerText}>{!!!files?.length && LABELS.NO_FILE_CHOOSEN}</div>
            {!!files?.length && (
                <div className="flex flex-wrap">
                    {files.map((file, idx) => {
                        let fileName = sanitizeFileNames(file?.name) || '';
                        fileName = fileName?.length > 32 ? `${fileName.slice(0, 32)}...` : fileName;

                        return (
                            <div key={idx} className={styles.files}>
                                <div className={styles.filesWrapper}>
                                    <div className={styles.containerText}>{fileName}</div>

                                    <div className={styles.icon}>
                                        <img
                                            onClick={() => removeFile(idx)}
                                            src={Bin}
                                            className={styles.iconImg}
                                            onMouseEnter={(e) => (e.currentTarget.src = Bin_Grad)}
                                            onMouseLeave={(e) => (e.currentTarget.src = Bin)}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                className={cx({ [styles.disabled]: files?.length >= 5 })}
                accept={acceptType}
                multiple={multiple}
                readOnly={isDisabled}
            />
        </div>
    );
};

export default React.memo(FileUpload);
