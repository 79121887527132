//libs
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { MenuPlacement } from 'react-select';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';

//styles
import styles from './PersonaForm.module.scss';

//constants
import { AUDIENCE, LABELS, STRINGS } from 'src/shared/strings';
import { AUDIENCE_INDUSTRY_OPTIONS, AUDIENCE_ROLE_OPTIONS } from 'src/shared/constants';
import { validateData } from '../../helpers';

//helpers
import { addToOptions } from 'src/shared/utils';

interface IFields {
    label: string;
    placeholder: string;
    value: string;
    error?: string;
    id: string;
    inputType?: string;
    selectOptions?: { label: string; value: string }[];
    placement?: MenuPlacement;
    isMulti?: boolean;
    type?: string;
    focus?: boolean;
    optional?: boolean;
}

interface IProps {
    data: any;
    segments: { label: string; value: string }[];
    onSave: (data: any) => void;
    onCancel: VoidFunction;
}

const PersonaForm = ({ data, segments, onSave, onCancel }: IProps) => {
    const [formData, setFormData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (Object.keys(data)?.length) {
            let kpis = data.kpis || data.personalityIndicators || '';
            let formattedData = {
                ...data,
                title: data?.title ? { label: data.title, value: data.title } : null,
                kpis: kpis?.split(',')?.map((kpi: string) => ({
                    label: kpi,
                    value: kpi
                })),
                industry: data.industry ? { label: data.industry, value: data.industry } : null,
                segment: data.segment ? { label: data.segment, value: data.segment } : null
            };

            setFormData(formattedData);
        }
    }, [data]);

    const onChange = (changedValue: any) => {
        const { id, value } = changedValue?.target || {};
        if (typeof value === 'string') {
            setFormData({ ...formData, [id]: value });
            return;
        }

        if (id === 'kpis') {
            setFormData({ ...formData, [id]: value });
            return;
        }

        setFormData({ ...formData, [id]: { label: value?.value, value: value?.value } });
    };

    const handleSave = () => {
        const errors = validateData(formData);
        setErrors({ ...validateData(formData) });
        if (errors && Object.values(errors).length) {
            return;
        }

        let data = formData;
        const resultObject = {} as { [key: string]: string };
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key] || '';

                switch (key) {
                    case 'title':
                    case 'segment':
                    case 'industry':
                        resultObject[key] = '';
                        if (typeof value === 'string') {
                            resultObject[key] = value;
                        } else if (value.label && value.value) {
                            resultObject[key] = value.value;
                        }
                        break;

                    case 'kpis':
                        resultObject[key] = '';
                        if (value instanceof Array && value?.length) {
                            resultObject[key] = value
                                ?.map((v: { value: string }) => v.value)
                                .join(', ');
                        }
                        break;

                    default:
                        resultObject[key] = value;
                }
            }
        }

        onSave(resultObject);
    };

    const renderPersonaFields = (fields: IFields[]) => {
        return (
            <div>
                <div className={cx(styles.fields)}>
                    {fields.map((field, key) => (
                        <CustomInput
                            key={key}
                            id={field.id}
                            label={field.label}
                            inputClass={styles.input}
                            value={field.value}
                            onChange={onChange}
                            error={field.error}
                            className={cx(styles.fieldsField)}
                            inputType={field.inputType}
                            inputProps={{
                                placeholder: field.placeholder,
                                ref: null
                            }}
                            selectOptions={field.selectOptions}
                            placement={field.placement}
                            isMulti={field.isMulti}
                            autoFocus={field.focus}
                            optional={field.optional}
                        />
                    ))}
                </div>

                <div className={cx('flex items-center justify-center', 'pt-4')}>
                    <CustomButton className={cx('mx-3')} title={LABELS.CANCEL} onClick={onCancel} />
                    <CustomButton
                        title={data.isClone ? LABELS.CLONE : LABELS.SAVE}
                        onClick={handleSave}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className={cx('py-2 px-2')}>
                <h1 className={cx('text-2xl font-semibold')}>{'Get Started'}</h1>
            </div>
            <div className={cx('border-2 rounded-lg shadow', 'my-2 p-4', 'bg-white')}>
                <div className={cx()}>
                    <h1 className={cx('text-xl font-semibold')}>
                        {data.isClone
                            ? STRINGS.CLONE_AUDIENCE_PERSONA
                            : STRINGS.CREATE_AUDIENCE_PERSONA}
                    </h1>
                </div>

                <div className={cx()}>
                    {renderPersonaFields([
                        {
                            label: 'Name*',
                            placeholder: 'e.g Mr Smith',
                            value: formData.name,
                            error: errors?.name,
                            id: 'name',
                            focus: true
                        },
                        {
                            label: `${AUDIENCE.SEGMENT}*`,
                            placeholder: 'e.g Federal, State, Local',
                            value: formData.segment || formData.segment?.value,
                            error: errors?.segment,
                            id: 'segment',
                            inputType: 'createableSelect',
                            placement: 'bottom',
                            isMulti: false,
                            selectOptions: addToOptions(
                                segments,
                                formData.segment || formData.segment?.value
                            )
                        },
                        {
                            label: AUDIENCE.INDUSTRY,
                            placeholder: `eg. Manufacturing`,
                            value: formData.industry || formData.industry?.value,
                            error: errors?.industry,
                            id: 'industry',
                            inputType: 'createableSelect',
                            selectOptions: addToOptions(
                                AUDIENCE_INDUSTRY_OPTIONS,
                                formData.industry || formData.industry?.value
                            ),
                            placement: 'bottom',
                            isMulti: false,
                            optional: true
                        },
                        {
                            label: AUDIENCE.COMPANY_PROFILE,
                            placeholder: 'eg. Bolt Inc.',
                            value: formData.companyName,
                            error: errors?.companyName,
                            id: 'companyName',
                            optional: true
                        },
                        {
                            label: `${AUDIENCE.TITLE}*`,
                            placeholder: 'eg. Marketing Director',
                            value: formData.title,
                            error: errors?.title,
                            id: 'title',
                            inputType: 'createableSelect',
                            selectOptions: addToOptions(AUDIENCE_ROLE_OPTIONS, formData.title),
                            placement: 'bottom',
                            isMulti: false
                        },
                        {
                            label: AUDIENCE.KEY_PERFORMANCE_INDICATORS,
                            placeholder: 'eg. Number of new contracts',
                            value: formData.kpis,
                            error: errors?.kpis,
                            id: 'kpis',
                            inputType: 'createableSelect',
                            selectOptions: addToOptions(
                                AUDIENCE_ROLE_OPTIONS?.find(
                                    (role) => role.value === formData.title?.value
                                )?.kpis || [],
                                formData.kpis
                            ),
                            placement: 'bottom',
                            isMulti: true,
                            optional: true
                        },
                        {
                            label: 'Offerings',
                            placeholder: 'eg. Primary Product ',
                            value: formData.offerings,
                            error: errors?.offerings,
                            id: 'offerings',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Job Responsibilities',
                            placeholder: 'eg. Engagement',
                            value: formData.jobResponsibilities,
                            error: errors?.jobResponsibilities,
                            id: 'jobResponsibilities',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Personal Motivators (Emotional)',
                            placeholder:
                                'eg. Getting to know the executive team on a personal level',
                            value: formData.personalMotivators,
                            error: errors?.personalMotivators,
                            id: 'personalMotivators',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Business Outcomes',
                            placeholder: 'eg. Sense of community',
                            value: formData.businessOutcomes,
                            error: errors?.businessOutcomes,
                            id: 'businessOutcomes',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Pain Points',
                            placeholder: 'eg. Being perceived as a nice to have function',
                            value: formData.frustrationsAndChallenges,
                            error: errors?.frustrationsAndChallenges,
                            id: 'frustrationsAndChallenges',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Use Cases',
                            placeholder: 'eg. Company announcements',
                            value: formData.useCases,
                            error: errors?.useCases,
                            id: 'useCases',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Triggers',
                            placeholder: 'eg. Shift to remote or hybrid work',
                            value: formData.purchaseTriggers,
                            error: errors?.purchaseTriggers,
                            id: 'purchaseTriggers',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Measures of Success',
                            placeholder: 'eg. Inclusivity metrics',
                            value: formData.successMeasures,
                            error: errors?.successMeasures,
                            id: 'successMeasures',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Common Objection',
                            placeholder: 'eg. I can`t get the budget',
                            value: formData.commonObjection,
                            error: errors?.commonObjection,
                            id: 'commonObjection',
                            inputType: 'textarea',
                            optional: true
                        },
                        {
                            label: 'Insight about the person',
                            placeholder: 'eg. Insights about the person',
                            value: formData.personInsight,
                            error: errors?.personInsight,
                            id: 'personInsight',
                            inputType: 'textarea',
                            optional: true
                        }
                    ])}
                </div>
            </div>
        </div>
    );
};

export default React.memo(PersonaForm);
