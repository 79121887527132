import { IOrg } from 'src/defs';

const transformArrayToString = (arr: string[]): string => {
    return Array.isArray(arr) ? arr.join(', ') : '';
};

const transformToSelect = (
    ctas: string[]
): {
    label: string;
    value: string;
}[] => {
    return Array.isArray(ctas) ? ctas.map((cta) => ({ label: cta, value: cta })) : [];
};

interface IStateData extends Omit<IOrg, 'geographies' | 'targetAudiences'> {
    geographies: string[];
    targetAudiences: string[];
}

export const transformStateData = (stateData: IStateData) => {
    const {
        ctas,
        industry,
        audienceSegments,
        successfulCampaigns,
        underperformingCampaigns,
        geographies,
        targetAudiences
    } = stateData || {};

    return {
        ...stateData,
        ctas: transformToSelect(ctas),
        industry: transformToSelect(industry),
        audienceSegments: transformToSelect(audienceSegments),
        successfulCampaigns: transformArrayToString(successfulCampaigns),
        underperformingCampaigns: transformArrayToString(underperformingCampaigns),
        geographies: transformArrayToString(geographies),
        targetAudiences: transformArrayToString(targetAudiences)
    };
};
