import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false
};

const rootSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        }
    }
});

export const { startLoading, stopLoading } = rootSlice.actions;

export default rootSlice.reducer;
