import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/content',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getUserContent: builder.query({
            query: (payload) => {
                const { page = 1, limit = 10, filter = 0 } = payload;
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: '/all',
                    params: { page, limit, filter }
                };
            }
        }),
        getContent: builder.query({
            query: (payload) => {
                const { id } = payload;
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        }),
        addContent: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/add`,
                    body: payload
                };
            }
        }),
        updateContent: builder.mutation({
            query: (payload) => {
                const { id, data } = payload;
                return {
                    method: 'PATCH',
                    headers: { ...headers },
                    url: `/${id}`,
                    body: data
                };
            }
        }),
        deleteContent: builder.query({
            query: (payload) => {
                const { id } = payload;
                return {
                    method: 'Delete',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        })
    })
});

export const {
    useLazyGetUserContentQuery,
    useLazyGetContentQuery,
    useLazyDeleteContentQuery,
    useAddContentMutation,
    useUpdateContentMutation
} = contentApi;
