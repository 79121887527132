import React from 'react';
import cx from 'classnames';

//icons
import { ICON_TAG } from 'src/shared/constants/icons';

//styles
import styles from './ResultContentTags.module.scss';

//helpers
import { getType } from './helper';

interface IProps {
    idx: number;
    subType: string;
}

const ResultContentTags = ({ idx, subType }: IProps) => {
    const tagStyles = (idx: number) =>
        cx(`flex items-center`, `mr-2 py-1 px-2 font-medium text-xs rounded-2xl`, {
            'bg-red-100 text-red-500': idx === 0,
            'bg-green-100 text-green-500': idx === 1,
            'bg-yellow-100 text-yellow-500': idx === 2
        });

    return (
        <div className={cx('flex justify-start mb-2')}>
            <div className={tagStyles(0)}>
                <span className={cx(ICON_TAG, styles.tag)} />
                {`BDR Type: ${getType(idx, subType)?.bdrType}`}
            </div>
            <div className={tagStyles(1)}>
                <span className={cx(ICON_TAG, styles.tag)} />
                {`Email Type: ${getType(idx, subType)?.emailType}`}
            </div>
            <div className={tagStyles(2)}>
                <span className={cx(ICON_TAG, styles.tag)} />
                {`Stage: ${idx + 1}`}
            </div>
        </div>
    );
};

export default React.memo(ResultContentTags);
