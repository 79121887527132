//libs
import React, { useState } from 'react';
import cx from 'classnames';

//constants
import { MAX_PASSWORD_LENGTH, passwordRegex } from 'src/shared/constants';
import { ICON_EYE, ICON_EYE_OFF } from 'src/shared/constants/icons';
import { ERRORS, LABELS, STRINGS } from 'src/shared/strings';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';

interface IFields {
    code: string;
    newPassword: string;
    confirmPassword: string;
}

interface IProps {
    handleSubmit: (values: IFields) => void;
}

export const ResetPasswordForm = ({ handleSubmit }: IProps) => {
    const [fields, setFields] = useState<IFields>({
        code: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [errors, setErrors] = useState<{
        code?: string;
        newPassword?: string;
        confirmPassword?: string;
    }>({});

    const INPUT_FILEDS = [
        {
            id: LABELS.CODE,
            label: LABELS.CODE_LABEL,
            max: 6,
            error: errors.code,
            value: fields.code
        },
        {
            id: LABELS.NEW_PASSWORD,
            label: LABELS.NEW_PASSWORD_LABEL,
            type: showNewPassword ? 'text' : 'password',
            passwordIcon: showNewPassword ? ICON_EYE : ICON_EYE_OFF,
            togglePassword: () => setShowNewPassword(!showNewPassword),
            max: MAX_PASSWORD_LENGTH,
            error: errors.newPassword,
            value: fields.newPassword
        },
        {
            id: LABELS.CONFIRM_PASSWORD_ID,
            label: LABELS.CONFIRM_PASSWORD_LABEL,
            type: showConfirmPassword ? 'text' : 'password',
            passwordIcon: showConfirmPassword ? ICON_EYE : ICON_EYE_OFF,
            togglePassword: () => setShowConfirmPassword(!showConfirmPassword),
            max: MAX_PASSWORD_LENGTH,
            error: errors.confirmPassword,
            value: fields.confirmPassword
        }
    ];

    const inputRefs = Array(INPUT_FILEDS.length).fill(React.createRef());

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setErrors((errors) => ({ ...errors, [id]: '' }));
        setFields((state) => ({ ...state, [id]: value }));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = validate(fields);
        setErrors(errors);

        if (Object.keys(errors)?.length) return;

        handleSubmit(fields);
    };

    return (
        <form onSubmit={onSubmit}>
            {INPUT_FILEDS.map((field, idx) => (
                <CustomInput
                    key={idx}
                    label={field.label}
                    inputRef={(r) => (inputRefs[idx] = r)}
                    id={field.id}
                    error={field.error}
                    onChange={handleChange}
                    value={field.value}
                    type={field.type}
                    icon={field.passwordIcon}
                    iconClass={cx('cursor-pointer')}
                    handleIconClick={field.togglePassword}
                    max={field.max}
                />
            ))}
            <div className={'flex flex-1 justify-center mt-2 h-[40px]'}>
                <CustomButton title={LABELS.SUBMIT} onClick={onSubmit} />
            </div>
        </form>
    );
};

const validate = (values: IFields) => {
    const errors = {} as IFields;
    if (!values.code) {
        errors.code = 'Required';
    }
    if (!values.newPassword) {
        errors.newPassword = 'Required';
    } else if (values.newPassword.length < 12) {
        errors.newPassword = STRINGS.MIN_PASSWORD;
    } else if (!passwordRegex.test(values.newPassword)) {
        errors.newPassword = ERRORS.PASSWORD;
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    }

    if (
        values.newPassword &&
        values.confirmPassword &&
        values.confirmPassword.localeCompare(values.newPassword) !== 0
    ) {
        errors.confirmPassword = 'Password does not match!';
    }
    return errors;
};
