export const validateForm = (
    data = {
        name: '',
        title: '',
        tone: '',
        writingStyle: '',
        personality: ''
    }
) => {
    const { name, title, tone, writingStyle, personality } = data;

    const errors = {};

    if (!name) {
        errors.name = `Please add pesona name!`;
    }

    if (!title) {
        errors.title = `Please add title!`;
    }

    if (!tone) {
        errors.tone = `Please add tone!`;
    }

    if (!writingStyle?.length) {
        errors.writingStyle = `Please add writing style!`;
    }

    if (!personality) {
        errors.personality = `Please add personality!`;
    }

    return { ...errors };
};
