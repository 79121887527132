import ga4 from 'react-ga4';
import store from 'src/redux/store';
import { STRINGS } from 'src/shared/strings';

const TRACKING_ID = 'G-QZ85MQZM27';
const isProduction = process.env.NODE_ENV === 'production';

export const init = () => {
    const userDetails = store?.getState()?.user?.userDetails;
    ga4.set({ userId: userDetails?.id });
    ga4.initialize(TRACKING_ID, {
        testMode: !isProduction || process.env.host_env === 'staging'
    });
};

export const sendEvent = (name = '') => {
    const userDetails = store?.getState()?.user?.userDetails;
    ga4.event('screen_view', {
        app_name: STRINGS.APP_NAME,
        screen_name: name,
        uid: userDetails?.id
    });
};

export const sendClickEvent = (event = '', screen_name = '', context = '') => {
    const userDetails = store?.getState()?.user?.userDetails;
    ga4.event(event || 'click_event', {
        app_name: STRINGS.APP_NAME,
        screen_name,
        context,
        uid: userDetails?.id
    });
};

export const sendPageview = (path = '') => {
    const userDetails = store?.getState()?.user?.userDetails;
    ga4.send({
        hitType: 'pageview',
        page: path,
        app_name: STRINGS.APP_NAME,
        uid: userDetails?.id
    });
};
