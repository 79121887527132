import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

const SocialButton = ({ className, title, onClick, icon }) => {
    return (
        <div className={cx(styles.container, className)} onClick={onClick}>
            <div className={styles.icon}>
                <img className={styles.iconImg} src={icon} alt="" />
            </div>
            <div className={styles.title}>{title}</div>
        </div>
    );
};

export default React.memo(SocialButton);
