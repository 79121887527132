//consts
import { ERRORS } from 'src/shared/strings';

//helpers
import { extractFileNameFromUrl } from 'src/shared/utils/helpers';

// const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

export const validateForm = (organizationData) => {
    const errors = {};

    if (!organizationData.name) {
        errors.name = ERRORS.REQUIRED;
    }
    if (!organizationData.industry?.length) {
        errors.industry = ERRORS.REQUIRED;
    }
    // if (!organizationData.websiteUrl) {
    //     errors.websiteUrl = ERRORS.REQUIRED;
    // }

    return errors;
};

export const formatFormValue = (data) => {
    const resultObject = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            switch (key) {
                case 'targetAudiences':
                case 'successfulCampaigns':
                case 'underperformingCampaigns':
                case 'geographies':
                    if (!value) {
                        resultObject[key] = '';
                    } else if (typeof value === 'string' && value.includes(',')) {
                        resultObject[key] = value.split(',').map((item) => item.trim());
                    } else {
                        resultObject[key] = [value];
                    }
                    break;
                case 'ctas':
                case 'industry':
                    resultObject[key] = [];
                    value?.forEach((item) => {
                        if (typeof item === 'string') {
                            resultObject[key]?.push(item);
                        } else if (item.label && item.value) {
                            resultObject[key]?.push(item.value);
                        }
                    });
                    break;
                case 'audienceSegments':
                    if (!value?.value) {
                        resultObject[key] = '';
                    } else {
                        resultObject[key] = [value.value];
                    }
                    break;

                default:
                    resultObject[key] = value;
            }
        }
    }

    return resultObject;
};

export const extractFileNames = (campaigns) => {
    if (!campaigns?.length) return [];

    return campaigns
        ?.split(', ')
        ?.map((e) => {
            if (e?.includes('clap')) {
                return { name: extractFileNameFromUrl(decodeURI(e)), url: e };
            }

            return null;
        })
        .filter(Boolean); // Filter out undefined values
};
