//libs
import React, { useRef, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import DeleteModal from 'src/components/DeleteModal';

//icons
import { ICON_ADD, ICON_BIN, ICON_PENCIL } from 'src/shared/constants/icons';

//helpers
import { useSelector } from 'react-redux';
import { IAudience } from 'src/defs';

interface IData extends Omit<IAudience, '_id'> {
    _id?: string;
    tooltip?: string;
    name?: string;
    userId?: string;
}

interface IProps {
    heading: string;
    headingTooltip?: string;
    data: {
        _id?: string;
        name?: string;
        label?: string;
        tooltip?: string;
        userId?: string;
        ownerId?: string;
    }[];
    error?: string;
    onSelect: (value: any) => void;
    selectedValues: string[] | IData[];
    allowAdd?: boolean;
    handleAdd?: (value?: any) => void;
    handleDelete?: (id: string) => void;
    deleteTitle?: string;
    deleteDesc?: string;
    errorRef?: null;
}

const CheckBlock = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    selectedValues = [],
    allowAdd,
    deleteDesc,
    deleteTitle,
    handleAdd,
    handleDelete,
    errorRef
}: IProps) => {
    const userId = useSelector((state: any) => state.user.userDetails?.id);

    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const tooltipRef = useRef(null);

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={() => {
                    handleDelete?.(deleteId);
                    setDeleteId('');
                    setShowDeleteModal(false);
                }}
                title={deleteTitle}
                desc={deleteDesc}
                setIsOpen={setShowDeleteModal}
            />
        );
    };

    const handleSelect = (item: any) => {
        onSelect(item);
    };

    const isSelected = (item: any): boolean => {
        if (!selectedValues?.length) return false;

        if (typeof selectedValues[0] === 'string') {
            return selectedValues?.includes(item?.value || item?._id);
        }

        let data = [...selectedValues];
        return !!data?.find(
            (selected) => typeof selected === 'object' && selected?.value === item?.value
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle}>
                <div className={styles.containerTitleWrap}>
                    <Tooltip
                        className={styles.containerTitleText}
                        title={heading}
                        tooltipText={headingTooltip}
                        tooltipRef={tooltipRef}
                    />
                    {allowAdd && (
                        <div
                            className={cx(ICON_ADD, styles.containerTitleIcon)}
                            onClick={() => handleAdd?.()}
                        />
                    )}
                </div>
                <span tabIndex={0} ref={errorRef} className={styles.containerTitleError}>
                    {error}
                </span>
            </div>

            <div className={styles.containerContent}>
                {data.map((item, key) => (
                    <div
                        key={key}
                        className={cx(styles.containerContentList, {
                            [styles.containerContentActive]: isSelected(item)
                        })}
                        onClick={() => handleSelect(item)}
                    >
                        <div className={styles.containerContentListTitle}>
                            {item?.name || item?.label}
                        </div>
                        <div
                            className={styles.containerContentListDesc}
                            dangerouslySetInnerHTML={{ __html: item.tooltip || '' }}
                        />

                        <div className={styles.containerContentListBox}>
                            <svg
                                className={cx(styles.checkbox, {
                                    [styles.checkboxActive]: isSelected(item)
                                })}
                                aria-hidden="true"
                                viewBox="0 0 15 11"
                                fill="none"
                            >
                                <path
                                    d="M1 4.5L5 9L14 1"
                                    strokeWidth="2"
                                    stroke={isSelected(item) ? '#fff' : 'none'}
                                />
                            </svg>
                        </div>

                        {(item?.userId || item?.ownerId) === userId && (
                            <div className={styles.icons}>
                                <div
                                    className={cx(ICON_BIN, styles.iconsDelete)}
                                    onClick={(e: { stopPropagation: () => void }) => {
                                        setDeleteId(item._id || '');
                                        setShowDeleteModal(true);
                                        e.stopPropagation();
                                    }}
                                />

                                <div
                                    className={cx(ICON_PENCIL, styles.iconsEdit)}
                                    onClick={(e: { stopPropagation: () => void }) => {
                                        handleAdd?.(item);
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {showDeletModal && deleteId && renderDeleteModal()}
        </div>
    );
};

export default React.memo(CheckBlock);
