import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

export const promptsApi = createApi({
    reducerPath: 'promptsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/prompts',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getPrompt: builder.query({
            query: ({ type }) => ({
                method: 'Get',
                headers: { ...headers },
                url: `/${type}`
            })
        })
    })
});

export const { useLazyGetPromptQuery } = promptsApi;
