import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userDetails: {},
    token: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateToken: (state, action) => {
            state.token = action.payload;
        },
        updateUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        clearStore: (state) => {
            state.userDetails = {};
            state.token = null;
        }
    }
});

export const { updateToken, updateUserDetails, clearStore } = userSlice.actions;

export default userSlice.reducer;
