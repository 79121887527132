import React from 'react';
import cx from 'classnames';

//constants
import { PERSONA } from 'src/shared/strings';
import { PERSONA_TONE_OPTIONS } from 'src/shared/constants';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from '../../Tabs.module.scss';

//widets
import Tile from '../Tile';

//defs
import { IPersona } from 'src/defs';

interface IProps {
    userId: string;
    userOrgs: { role: string; code: number; name: string; isDefault: boolean }[];
    personasData: IPersona[];
    activePersona: string | number;
    handleShowPersonaForm: (persona: {}, active: boolean, key?: number) => Promise<void>;
    setActivePersona: (org: string | number) => void;
    setDeleteData: (data: { title: string; desc: string }) => void;
    setDeleteId: (data: { type: string; id: string }) => void;
    setShowDeleteModal: (value: boolean) => void;
}

const PersonaTab = ({
    userId,
    userOrgs,
    personasData,
    activePersona,
    handleShowPersonaForm,
    setActivePersona,
    setDeleteData,
    setDeleteId,
    setShowDeleteModal
}: IProps) => {
    const renderPersonaTile = (data: IPersona) => {
        const errors = {
            name: '',
            title: '',
            tone: '',
            personality: '',
            writingStyle: '',
            description: ''
        };

        return [
            {
                label: PERSONA.NAME,
                placeholder: 'eg. Persuader',
                value: data.name,
                error: errors?.name,
                id: 'name',
                tooltip: 'Give a short title for this persona. E.g., BDR or Sales Exec'
            },
            {
                label: PERSONA.TITLE,
                placeholder: 'eg. CEO',
                value: data.title,
                error: errors?.title,
                id: 'title'
            },
            {
                label: PERSONA.TONE,
                placeholder: 'eg. Serious',
                value: data.tone,
                error: errors?.tone,
                id: 'tone',
                inputType: 'createableSelect',
                selectOptions: PERSONA_TONE_OPTIONS.sort((a, b) => a.value.localeCompare(b.value)),
                placement: 'bottom',
                isMulti: false,
                tooltip: 'What attitude do you want the writer to convey?'
            },

            {
                label: PERSONA.PERSONALITY,
                placeholder: 'eg. friendly, but to the point',
                value: data.personality,
                error: errors?.personality,
                id: 'personality',
                tooltip: 'What personality do you want the writer to assume?',
                inputType: 'textarea'
            },
            {
                label: PERSONA.STYLE,
                placeholder: `eg. persuasive writing`,
                value: data.writingStyle,
                error: errors?.writingStyle,
                id: 'writingStyle',
                inputType: 'textarea',
                tooltip:
                    'This is how a writer expresses ideas in their content. Tell us the phrases or terms you want to avoid, your grammar style, punctuation (or lack thereof), reading grade, etc.'
            },
            {
                label: PERSONA.DESCRIPTION,
                placeholder: `eg.Suitable for ...`,
                value: data.description || data.tooltip,
                error: errors?.description,
                id: 'description',
                inputType: 'textarea'
            }
        ].map((persona, key) => <Tile key={key} data={persona} />);
    };

    return (
        <div>
            <div className={cx('flex justify-end')}>
                <h1
                    className={cx(
                        'py-1 px-4 my-2 text-white bg-blue-600 shadow rounded-xl shadow w-50 text-center cursor-pointer',
                        'hover:bg-blue-700 font-bold'
                    )}
                    onClick={() => handleShowPersonaForm({}, true)}
                >
                    {'Add Persona'}
                </h1>
            </div>
            {personasData
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                .map((persona, key) => {
                    const isActivePersona = activePersona === persona._id;
                    const orgDetails = userOrgs.find(
                        (uo) => Number(uo.code) === Number(persona.orgId)
                    );
                    const isAdmin = orgDetails?.role === 'admin';
                    const isOwner = userId === persona.userId;
                    const showEditDelete = isOwner || isAdmin;
                    return (
                        <div key={key} className={cx('')}>
                            <div
                                className={cx(
                                    'relative flex items-center justify-between text-lg bg-slate-100',
                                    'p-2 my-2 rounded cursor-pointer hover:bg-slate-300',
                                    { 'bg-slate-300': activePersona === persona._id }
                                )}
                                onClick={() =>
                                    setActivePersona(
                                        activePersona === persona._id ? '' : persona._id
                                    )
                                }
                            >
                                {persona?.name}
                                <div
                                    className={cx(
                                        styles.org,
                                        'flex items-center w-64 text-cyan-900'
                                    )}
                                >
                                    <Icomoon className={cx(styles.orgIcon)} icon={'organization'} />
                                    {orgDetails?.name}
                                </div>

                                {isActivePersona && showEditDelete && (
                                    <div className={cx('flex')}>
                                        <Icomoon
                                            className={styles.edit}
                                            icon={'bin'}
                                            onClick={(e: { stopPropagation: () => void }) => {
                                                setDeleteData({
                                                    title: 'Delete Persona',
                                                    desc: 'Are you sure you want to delete the persona ?'
                                                });
                                                setShowDeleteModal(true);
                                                setDeleteId({
                                                    type: 'persona',
                                                    id: persona._id
                                                });
                                                e.stopPropagation();
                                            }}
                                        />
                                        <Icomoon
                                            className={styles.edit}
                                            icon={'pencil'}
                                            onClick={(e: { stopPropagation: () => void }) => {
                                                handleShowPersonaForm(persona, true, key);
                                                e.stopPropagation();
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {activePersona === persona._id && (
                                <div className={cx('flex flex-wrap')}>
                                    {renderPersonaTile(persona)}
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default React.memo(PersonaTab);
