//hook
import { useDispatch } from 'react-redux';

//api
import { useLazyGetUserQuery } from 'src/redux/queries';

//helpers
import { toast } from 'react-toastify';
import { updateUserDetails } from 'src/redux/reducers/userReducer';

const useUpdateUserDetails = () => {
    const dispatch = useDispatch();

    const [getUser] = useLazyGetUserQuery();

    const updateUser = async () => {
        try {
            await getUser()
                .unwrap()
                .then((data) => {
                    const userData = { ...data?.data?.user, data: [] };
                    dispatch(updateUserDetails(userData));
                })
                .catch((err) => toast.error(err?.data?.message));
        } catch (error) {
            toast.error(error?.data?.message);
        }
    };

    return updateUser;
};

export default useUpdateUserDetails;
