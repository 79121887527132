//lib
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//constants
import { ROUTES } from 'src/shared/constants';
import { STRINGS } from 'src/shared/strings';

//styles
import styles from './styles.module.scss';

//assets
import DEFAULT_USER from 'src/assets/icons/user.svg';
import Icomoon from 'src/assets/Icomoon';

const AppSidebar = ({ setShowAddCampaignModal }) => {
    const userDetails = useSelector((state) => state.user.userDetails);
    const handleNavigation = useCustomNavigation();
    const loc = useLocation();

    const [fullSidebar, setFullSidebar] = useState(true);

    useEffect(() => {
        if (loc?.pathname !== ROUTES.HOME) {
            setFullSidebar(false);
        } else {
            setFullSidebar(true);
        }
    }, [loc]);

    const gotToProfile = () => {
        handleNavigation(ROUTES.PROFILE);
    };

    const gotToHome = () => {
        handleNavigation(ROUTES.HOME);
    };

    const goToOrg = () => {
        handleNavigation(ROUTES.ORG);
    };

    const AppLogo = () => {
        return (
            <h1
                onClick={gotToHome}
                className={cx(styles.containerTitle, {
                    [styles.containerTitleSmall]: !fullSidebar
                })}
                aria-label={STRINGS.APP_NAME}
            >
                {fullSidebar ? (
                    STRINGS.APP_NAME
                ) : (
                    <Icomoon className={styles.containerTitleIcon} icon={'home'} />
                )}
            </h1>
        );
    };

    const UserChip = () => {
        return (
            <div
                className={cx(styles.user, { [styles.userSmall]: !fullSidebar })}
                onClick={gotToProfile}
            >
                <div className={cx(styles.userImage, { [styles.userSmallImage]: !fullSidebar })}>
                    <img
                        className={styles.userImagePic}
                        src={userDetails?.picture || DEFAULT_USER}
                        alt=""
                    />
                </div>
                {fullSidebar && (
                    <div className={styles.userDetail}>
                        <div className={styles.userDetailName}>
                            {userDetails?.name?.split(' ')[0]}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // const Campaigns = () => {
    //     if (!fullSidebar) return null;
    //     return (
    //         <div className={styles.campaign}>
    //             <div className={styles.title}>{STRINGS.CAMPAIGNS}</div>
    //             <ul className={styles.campaignList}>
    //                 <li className={styles.campaignListItem}>Campaign 1</li>
    //                 <li className={styles.campaignListItem}>Campaign 2</li>
    //             </ul>
    //         </div>
    //     );
    // };

    const OrgData = () => {
        return (
            <div className={cx('border-b-2 mb-4 ', styles.org)}>
                <div
                    className={cx(
                        'flex items-center py-2 px-2 text-base font-medium cursor-pointer rounded-lg',
                        styles.orgCta,
                        {
                            [styles.active]: loc.pathname?.includes(ROUTES.ORG)
                        }
                    )}
                    onClick={goToOrg}
                >
                    <Icomoon className={styles.orgIcon} icon={'organization'} />
                    {fullSidebar && STRINGS.ORG}
                </div>
            </div>
        );
    };

    const AddCampaign = () => {
        return (
            <div
                className={cx(styles.add, { [styles.addSmall]: !fullSidebar })}
                onClick={() => setShowAddCampaignModal(true)}
            >
                <Icomoon className={styles.addIcon} icon={'add'} />
            </div>
        );
    };

    return (
        <div className={cx(styles.container, { [styles.containerSmall]: !fullSidebar })}>
            <div className="w-full">
                <AppLogo />
                <AddCampaign />
                {/* <Campaigns /> */}
            </div>

            <div className="w-full">
                <OrgData />
                <UserChip />
            </div>
        </div>
    );
};

export default React.memo(AppSidebar);
