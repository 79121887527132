import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//views
import {
    Campaign,
    Home,
    HubspotSuccess,
    LinkedinSuccess,
    OrgIndexPage,
    OrgOnboarding,
    Profile
} from 'src/views/Private';
import { Privacy, Tnc } from 'src/views';

//components
import { AppSidebar } from 'src/components';

//helpers
import { analytics } from 'src/shared/utils/Analytics';
import { updateConfig } from 'src/redux/reducers/configReducer';
import { clearStore } from 'src/redux/reducers/userReducer';

//api
import { useLazyGetConfigQuery } from 'src/redux/queries/config';

//constants
import { ROUTES, STATUS } from 'src/shared/constants';
import { toast } from 'react-toastify';

//widgets
import AddCampaignTypeModal from 'src/components/AddCampaignTypeModal';

const PrivateRoutes = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [getConfig] = useLazyGetConfigQuery();

    const [showAdCampaignModal, setShowAddCampaignModal] = useState(false);
    const userOrgs = useSelector((state: any) => state.user.userDetails?.orgs) || [];

    useEffect(() => {
        const path = location.pathname + location.search;
        analytics.sendPageview(path);
    }, [location]);

    useEffect(() => {
        getConfig(null)
            .unwrap()
            .then((data) => {
                if (data) dispatch(updateConfig(data));
            })
            .catch((err) => {
                if (err.status === STATUS.unauthorized) {
                    dispatch(clearStore());
                    toast.error(err?.message || err?.data?.message);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCampaignModalClose = () => {
        setShowAddCampaignModal(false);
    };

    const ORG_ROUTES = [
        { path: ROUTES.HOME, element: <Home /> },
        { path: ROUTES.CAMPAIGN, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_ID, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_BDR_CADENCE_ID, element: <Campaign /> },
        { path: ROUTES.PROFILE, element: <Profile /> },
        { path: ROUTES.ORG, element: <OrgIndexPage /> },
        { path: ROUTES.ORG_ONBOARDING, element: <OrgOnboarding /> },
        { path: ROUTES.PRIVACY, element: <Privacy /> },
        { path: ROUTES.TNC, element: <Tnc /> },
        { path: ROUTES.CAMPAIGN_PESONALIZED, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_PESONALIZED_ID, element: <Campaign /> },
        { path: ROUTES.HUBSPOT_AUTH_SUCCESSS, element: <HubspotSuccess /> },
        { path: ROUTES.Linkedin_AUTH_SUCCESSS, element: <LinkedinSuccess /> },
        { path: '*', element: <Navigate replace to={ROUTES.HOME} /> }
    ];

    const NO_ORG_ROUTES = [
        { path: ROUTES.ORG_ONBOARDING, element: <OrgOnboarding /> },
        { path: '*', element: <Navigate replace to={ROUTES.ORG_ONBOARDING} /> }
    ];

    return (
        <div className="flex">
            {!!userOrgs?.length && <AppSidebar setShowAddCampaignModal={setShowAddCampaignModal} />}
            <Routes>
                {userOrgs?.length
                    ? ORG_ROUTES.map(({ path, element }, key) => (
                          <Route key={key} path={path} element={element} />
                      ))
                    : NO_ORG_ROUTES.map(({ path, element }, key) => (
                          <Route key={key} path={path} element={element} />
                      ))}
            </Routes>

            {showAdCampaignModal && <AddCampaignTypeModal handleClose={handleCampaignModalClose} />}
        </div>
    );
};

export default React.memo(PrivateRoutes);
