export const getType = (idx: number, type?: string) => {
    let bdrType = 'Cold Lead';
    switch (type) {
        case 'Cold Lead':
            switch (idx) {
                case 0:
                    return { bdrType, emailType: 'Introduction' };
                case 1:
                    return { bdrType, emailType: 'Benefits' };
                case 2:
                    return { bdrType, emailType: 'Insight' };
                case 3:
                    return { bdrType, emailType: 'Case Study' };
                case 4:
                    return { bdrType, emailType: 'Asset' };
                case 5:
                    return { bdrType, emailType: 'Resurface' };
                case 6:
                    return { bdrType, emailType: 'Break-up' };
            }
            break;
        case 'Warm Lead':
            bdrType = 'Warm Lead';
            switch (idx) {
                case 0:
                    return { bdrType, emailType: 'Follow-Up 1' };
                case 1:
                    return { bdrType, emailType: 'Follow-Up 2' };
                case 2:
                    return { bdrType, emailType: 'Webinar Invite' };
                case 3:
                    return { bdrType, emailType: 'Insight' };
                case 4:
                    return { bdrType, emailType: 'Case study 1' };
                case 5:
                    return { bdrType, emailType: 'Case study 2' };
                case 6:
                    return { bdrType, emailType: 'Break-up' };
            }
            break;
        case 'Engaged Lead':
            bdrType = 'Engaged Lead';
            switch (idx) {
                case 0:
                    return { bdrType, emailType: 'Follow-Up 1' };
                case 1:
                    return { bdrType, emailType: 'Personalized assessment 1' };
                case 2:
                    return { bdrType, emailType: 'Detailed benefit #1' };
                case 3:
                    return { bdrType, emailType: 'Personalized assessment 2' };
                case 4:
                    return { bdrType, emailType: 'Detailed benefit #2' };
                case 5:
                    return { bdrType, emailType: 'Tailored proposal # 1' };
                case 6:
                    return { bdrType, emailType: 'Break-up' };
            }
            break;
        case 'Custom Template 1':
            return { bdrType: 'Custom Template', emailType: 'Custom Template 1' };
        case 'Custom Template 2':
            return { bdrType: 'Custom Template', emailType: 'Custom Template 2' };
        case 'Custom Template 3':
            return { bdrType: 'Custom Template', emailType: 'Custom Template 3' };
        case `Custom Combined Templates`:
            bdrType = 'Custom Combined Templates';
            switch (idx) {
                case 0:
                    return { bdrType, emailType: 'Custom Template 1' };
                case 1:
                    return { bdrType, emailType: 'Custom Template 2' };
                case 2:
                    return { bdrType, emailType: 'Custom Template 3' };
            }
    }
};
