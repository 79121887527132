import React, { useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

//styles
import styles from './Tabs.module.scss';

//widgets
import DeleteModal from 'src/components/DeleteModal';
import OrgsTab from './widgets/OrgsTab';
import PersonaTab from './widgets/PersonaTab';
import AudienceTab from './widgets/AudienceTab';

const Tabs = ({
    activeTab = 1,
    setActiveTab = () => {},
    userOrgs = [],
    orgsData = [],
    personasData = [],
    targetAudienceData = [],
    defaultOrg = '',
    handleAddNewOrg = () => {},
    handleShowPersonaForm = () => {},
    handleShowAudienceForm = () => {},
    handleDeletePersona = () => {},
    handleDeleteAudience = () => {},
    handleUserRoleChange = () => {},
    handleDeleteOrgMember = () => {},
    handleAddMemberToOrg = () => {},
    handleDefaultOrg = () => {},
    handleEditOrgSuccess
}) => {
    const userId = useSelector((state) => state.user.userDetails?.id);

    const [activeOrg, setActiveOrg] = useState('');
    const [activePersona, setActivePersona] = useState('');
    const [activeAudience, setActiveAudience] = useState('');
    const [isEditingOrgId, setIsEditingOrgId] = useState('');
    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({ type: '', id: '' });
    const [deleteData, setDeleteData] = useState({ title: '', desc: '' });

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={() => {
                    if (deleteId.type === 'audience') {
                        handleDeleteAudience(deleteId.id);
                    } else if (deleteId.type === 'user') {
                        handleDeleteOrgMember(deleteId.id, activeOrg);
                    } else {
                        handleDeletePersona(deleteId.id);
                    }
                    setDeleteId({ type: '', id: '' });
                    setShowDeleteModal(false);
                }}
                title={deleteData.title}
                desc={deleteData.desc}
                setIsOpen={setShowDeleteModal}
            />
        );
    };

    const addMember = (value, hideModal) => {
        handleAddMemberToOrg({ user: value, id: activeOrg }, hideModal);
    };

    const handleOrgUpdate = (org) => {
        setIsEditingOrgId('');
        handleEditOrgSuccess(org);
    };

    const renderTabData = () => {
        switch (activeTab) {
            case 1:
                return (
                    <OrgsTab
                        userOrgs={userOrgs}
                        orgsData={orgsData}
                        activeOrg={activeOrg}
                        isEditingOrgId={isEditingOrgId}
                        defaultOrg={defaultOrg}
                        addMember={addMember}
                        handleAddNewOrg={handleAddNewOrg}
                        handleDefaultOrg={handleDefaultOrg}
                        setActiveOrg={setActiveOrg}
                        setIsEditingOrgId={setIsEditingOrgId}
                        handleUserRoleChange={handleUserRoleChange}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                        handleEditOrgSuccess={handleOrgUpdate}
                    />
                );
            case 2:
                return (
                    <PersonaTab
                        userId={userId}
                        userOrgs={userOrgs}
                        personasData={personasData}
                        activePersona={activePersona}
                        setActivePersona={setActivePersona}
                        handleShowPersonaForm={handleShowPersonaForm}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                    />
                );
            case 3:
                return (
                    <AudienceTab
                        targetAudienceData={targetAudienceData}
                        userId={userId}
                        userOrgs={userOrgs}
                        activeAudience={activeAudience}
                        handleShowAudienceForm={handleShowAudienceForm}
                        setActiveAudience={setActiveAudience}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className={cx('w-full')}>
            <div className={cx('flex')}>
                {['Your Orgs', 'Writer Personas', 'Target Audiences'].map((target, key) => {
                    return (
                        <h1
                            className={cx(
                                'rounded-t-lg text-xl mt-4 mx-8 p-4 cursor-pointer hover:bg-white',
                                'hover:border-white font-semibold',
                                activeTab === key + 1 ? 'bg-white' : 'bg-slate-200'
                            )}
                            key={key}
                            onClick={() => {
                                setActiveTab(key + 1);
                            }}
                        >
                            {target}
                        </h1>
                    );
                })}
            </div>
            <div className={cx(styles.tabData, ' bg-white px-2 py-3 rounded-md')}>
                {renderTabData()}
            </div>
            {showDeletModal && deleteId && renderDeleteModal()}
        </div>
    );
};

export default React.memo(Tabs);
