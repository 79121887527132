export const validateData = (
    data = {
        name: '',
        title: '',
        segment: ''
    }
) => {
    const { name, title, segment } = data;

    const errors = {} as any;

    if (!title) {
        errors.title = `Please add Persona title!`;
    }

    if (!name) {
        errors.name = `Please add Persona name!`;
    }

    if (!segment) {
        errors.segment = `Please select Persona segment!`;
    }

    return { ...errors };
};
