import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getSocialUser: builder.query({
            query: () => ({
                method: 'Get',
                credentials: 'include',
                headers: { ...headers },
                url: 'auth/login/success'
            })
        }),
        signup: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: 'auth/signup',
                body: payload
            })
        }),
        login: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: 'auth/login',
                body: payload
            })
        }),
        verify: builder.query({
            query: ({ token }) => ({
                method: 'Get',
                credentials: 'include',
                headers: { ...headers },
                url: `auth/verify`,
                params: { token }
            })
        }),
        logout: builder.query({
            query: () => ({
                method: 'Get',
                credentials: 'include',
                headers: { ...headers },
                url: 'auth/logout'
            })
        })
    })
});

export const {
    useLazyGetSocialUserQuery,
    useLazyLogoutQuery,
    useSignupMutation,
    useLoginMutation,
    useLazyVerifyQuery
} = authApi;
