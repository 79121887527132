//constants
import { CONTENT_FILE_TYPES } from 'src/shared/constants';
import { ERRORS } from 'src/shared/strings';

export const validateData = (
    data = {
        file: [],
        contentType: [],
        toneRegister: '',
        audience: '',
        title: '',
        goal: '',
        theme: '',
        demography: '',
        relevantProducts: '',
        subType: '',
        persona: ''
    }
) => {
    const { file, contentType, toneRegister, audience, title, subType, persona } = data;

    const errors = {};

    if (!title) {
        errors.title = `Please add campaign title!`;
    }

    if (contentType.includes('bdrCadence') && !subType) {
        errors.subType = `Please select BDR type!`;
    }

    if (!file.length) {
        errors.file = `Please add atleast one source!`;
    } else {
        file.forEach((f) => {
            if (
                (f.type === CONTENT_FILE_TYPES.PDF.value ||
                    f?.type === CONTENT_FILE_TYPES.DOCX.value) &&
                !f?.files?.length
            ) {
                errors.file = ERRORS.UPLOAD_FILE;
            } else if (f?.type === CONTENT_FILE_TYPES.TEXT.value && !f?.value?.length) {
                errors.file = ERRORS.EMPTY_CONTENT_INPUT;
            } else if (f?.type === CONTENT_FILE_TYPES.URL.value && !f?.value?.length) {
                errors.file = ERRORS.EMPTY_URL_INPUT;
            }
        });
    }

    if (!contentType || contentType.length <= 0) {
        errors.contentType = ERRORS.EMPTY_CONTENT_TYPE;
    }
    if (!toneRegister || toneRegister.length <= 0) {
        errors.toneRegister = ERRORS.REQUIRED;
    }
    if (!audience || audience.length <= 0) {
        errors.audience = ERRORS.REQUIRED;
    }

    if (!persona || persona.length <= 0) {
        errors.persona = ERRORS.REQUIRED;
    }

    return { ...errors };
};
