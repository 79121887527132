import React, { useState } from 'react';

//styles
import styles from './styles.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';

const CustomInputSeparator = ({ values = [], onChange, label = '', error = '' }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ';') {
            e.preventDefault();
            if (inputValue.trim() !== '') {
                onChange([...values, inputValue.trim()]);
            }
            setInputValue('');
        }
    };

    const handleInputBlur = () => {
        if (inputValue.trim() !== '') {
            onChange([...values, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleRemoveItem = (index) => {
        const updatedItems = values.slice();
        updatedItems.splice(index, 1);
        onChange(updatedItems);
    };

    return (
        <div>
            <div className={styles.container}>
                {label && (
                    <div className={styles.info}>
                        <label className={styles.infoLabel}>{label}</label>
                        <span className={styles.infoError}>{error}</span>
                    </div>
                )}
                {values.map((item, index) => (
                    <div
                        key={index}
                        className={styles.containerItem}
                        onClick={() => handleRemoveItem(index)}
                    >
                        {item}
                    </div>
                ))}
                <input
                    className={styles.containerInput}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onBlur={handleInputBlur}
                    placeholder={STRINGS.URLS_PLACEHOLDER}
                    autoFocus
                />
            </div>
        </div>
    );
};

export default React.memo(CustomInputSeparator);
