import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isGpt4Enabled: true,
    fileChunkSize: 10000,
    summaryLength: 300
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (state, action) => {
            state.isGpt4Enabled = action.payload.isGpt4Enabled;
            state.fileChunkSize = action.payload.fileChunkSize;
            state.summaryLength = action.payload.summaryLength;
            state.isHubspotEnabled = action.payload.isHubspotEnabled;
        }
    }
});

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
