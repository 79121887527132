//libs
import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import { RadioButton } from 'src/widgets';

const RadioTitle = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    value = ''
}) => {
    const handleSelect = (item) => {
        onSelect(item);
    };

    const radioClass = (item) =>
        cx(styles.containerContentRadio, {
            [styles.containerContentRadioChecked]: value === item.value
        });

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle}>
                <Tooltip
                    className={styles.containerTitleText}
                    title={heading}
                    tooltipText={headingTooltip}
                />
                <span className={styles.containerTitleError}>{error}</span>
            </div>
            <div className={styles.containerContent}>
                {data.map((item, key) => (
                    <RadioButton
                        className={radioClass(item)}
                        key={key}
                        data={item}
                        id={`${heading}_${key}`}
                        onChange={() => handleSelect(item)}
                        checked={value === item.value}
                    />
                ))}
            </div>
        </div>
    );
};

export default React.memo(RadioTitle);
