import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//helpers
import { useLazyOauthHubspotQuery } from 'src/redux/queries/Hubspot';
import { startLoading, stopLoading } from 'src/redux/reducers';

const Hubspot = () => {
    const dispatch = useDispatch();
    const loc = useLocation();

    const [oauthHubspot] = useLazyOauthHubspotQuery();
    const handleNavigation = useCustomNavigation();

    const errorToast = () =>
        toast.error('Oops login failed!', {
            toastId: 'loginFailed'
        });

    useEffect(() => {
        handleHubspotRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHubspotRedirect = async () => {
        try {
            dispatch(startLoading());
            const code = loc.search?.split('=')[1];
            await oauthHubspot({ code });

            const hubspotRedirect = localStorage.getItem('hubspot_redirect') || null;
            if (hubspotRedirect) {
                handleNavigation(hubspotRedirect, true);
                localStorage.removeItem('hubspot_redirect');
            }
        } catch (err) {
            errorToast();
        } finally {
            dispatch(stopLoading());
        }
    };

    return <></>;
};

export default React.memo(Hubspot);
