import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { encryptPayload, generateFilePayload } from 'src/shared/utils';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        readAndSaveFile: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'readFile',
                body: generateFilePayload(payload)
            })
        }),
        getResults: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'getResults',
                body: encryptPayload(payload, token)
            })
        }),
        getUser: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: 'user/my'
                };
            }
        }),
        getUserHistory: builder.query({
            query: (payload) => {
                const { page = 1, limit = 10, filter = 0 } = payload;
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: 'user/history',
                    params: { page, limit, filter }
                };
            }
        }),
        downloadFile: builder.query({
            query: ({ file, name }) => ({
                method: 'Get',
                headers: {},
                url: 'fileDownload',
                params: {
                    file: encryptPayload(file, token).data,
                    name: encryptPayload(name, token).data
                }
            })
        }),
        uploadFile: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'uploadFile',
                body: generateFilePayload(payload)
            })
        }),
        updateUser: builder.mutation({
            query: (payload) => ({
                method: 'PATCH',
                headers: {},
                url: 'user/update',
                body: payload
            })
        }),
        updateUserPassword: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'password/change',
                body: payload
            })
        }),
        forgotPassword: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'password/forgot',
                body: payload
            })
        }),
        resetPassword: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: {},
                url: 'password/reset',
                body: payload
            })
        })
    })
});

export const {
    useReadAndSaveFileMutation,
    useUploadFileMutation,
    useGetResultsMutation,
    useLazyGetUserQuery,
    useLazyDownloadFileQuery,
    useLazyGetUserHistoryQuery,
    useUpdateUserMutation,
    useUpdateUserPasswordMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = usersApi;
