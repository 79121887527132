import React from 'react';
import cx from 'classnames';

//components
import TextArea from 'src/components/TextArea';

//styles
import styles from './styles.module.scss';

//helper
import { formatMarkdown } from 'src/shared/utils';

const CustomTextArea = ({
    className = '',
    value = '',
    onChange,
    id = '',
    showCount = true,
    noClap = false
}) => {
    value = formatMarkdown(value instanceof Array ? value[0] : value);
    const wordCount = value ? value?.split(' ')?.length : 0;

    return (
        <div id={id} className={cx(styles.container, className)}>
            {!!showCount && <div className={cx(styles.count)}>{wordCount} words</div>}
            <TextArea
                content={value.replace(/\n/g, '<br />')}
                onChange={onChange}
                noClap={noClap}
            />
        </div>
    );
};

export default React.memo(CustomTextArea);
