//libs
import React, { useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import Icomoon from 'src/assets/Icomoon';

//helpers
import { useSelector } from 'react-redux';
import DeleteModal from 'src/components/DeleteModal';

const RadioBlock = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    value = '',
    allowAdd = false,
    handleAdd,
    handleDelete,
    deleteTitle = '',
    deleteDesc = ''
}) => {
    const userId = useSelector((state) => state.user.userDetails?.id);

    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const handleSelect = (item) => {
        onSelect(item);
    };

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={() => {
                    handleDelete(deleteId);
                    setDeleteId('');
                    setShowDeleteModal(false);
                }}
                title={deleteTitle}
                desc={deleteDesc}
                setIsOpen={setShowDeleteModal}
            />
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle}>
                <div className={styles.containerTitleWrap}>
                    <Tooltip
                        className={styles.containerTitleText}
                        title={heading}
                        tooltipText={headingTooltip}
                    />
                    {allowAdd && (
                        <Icomoon
                            className={styles.containerTitleIcon}
                            icon={'add'}
                            onClick={() => handleAdd()}
                        />
                    )}
                </div>
                <span className={styles.containerTitleError}>{error}</span>
            </div>

            <div className={styles.containerContent}>
                {data.map((item, key) => (
                    <div
                        key={key}
                        className={cx(styles.containerContentList, {
                            [styles.containerContentActive]:
                                value === item.name || value === item.value
                        })}
                        onClick={() => handleSelect(item)}
                    >
                        <div className={styles.containerContentListTitle}>
                            {item?.name || item?.label}
                        </div>
                        <div className={styles.containerContentListDesc}>{item?.tooltip}</div>
                        <div
                            className={cx(styles.containerContentListCircle, {
                                [styles.containerContentListCircleChecked]:
                                    value === item.name || value === item.value
                            })}
                        />
                        {(item?.userId || item?.ownerId) === userId && (
                            <div className={styles.icons}>
                                <Icomoon
                                    className={styles.iconsDelete}
                                    icon={'bin'}
                                    onClick={(e) => {
                                        setDeleteId(item._id);
                                        setShowDeleteModal(true);
                                        e.stopPropagation();
                                    }}
                                />

                                <Icomoon
                                    className={styles.iconsEdit}
                                    icon={'pencil'}
                                    onClick={(e) => {
                                        handleAdd(item);
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {showDeletModal && deleteId && renderDeleteModal()}
        </div>
    );
};

export default React.memo(RadioBlock);
