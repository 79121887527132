import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

export const documentTypesApi = createApi({
    reducerPath: 'documentTypesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/documentTypes',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getdocumentTypes: builder.query({
            query: () => ({
                method: 'Get',
                headers: { ...headers },
                url: '/all'
            })
        })
    })
});

export const { useLazyGetdocumentTypesQuery } = documentTypesApi;
