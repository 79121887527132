export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    LOGIN_SUCCESS: '/login/success',
    SIGNUP: '/signup',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    PROFILE: '/profile',
    CAMPAIGN: '/campaign',
    CAMPAIGN_BDR_CADENCE: '/campaign/bdr-cadence',
    CAMPAIGN_BDR_CADENCE_ID: '/campaign/bdr-cadence/:id',
    CAMPAIGN_PESONALIZED: '/campaign/personalized',
    CAMPAIGN_PESONALIZED_ID: '/campaign/personalized/:id',
    CAMPAIGN_ID: '/campaign/:id',
    ORG: '/org',
    ORG_ONBOARDING: '/org/onboarding',
    PRIVACY: '/privacy',
    TNC: '/terms',
    HUBSPOT_AUTH_SUCCESSS: '/hubspot/oauth-callback',
    Linkedin_AUTH_SUCCESSS: '/linkedin/oauth-callback'
};
