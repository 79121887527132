import React, { useEffect, useState } from 'react';

//widgets
import { SocialButton } from '../widgets';

//styles
import styles from './styles.module.scss';

//assets
import GoogleIcon from 'src/assets/icons/google.svg';
// import FbIcon from 'src/assets/icons/facebook.svg';

//constants
import { LABELS, STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants';

//components
import { CustomButton, CustomInput } from 'src/widgets';
import { toast } from 'react-toastify';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { useLocation } from 'react-router-dom';

//apis
import { useLazyVerifyQuery, useSignupMutation } from 'src/redux/queries';
import { useDispatch } from 'react-redux';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { updateToken, updateUserDetails } from 'src/redux/reducers/userReducer';

//analytics
import { analytics, ANALYTICS } from 'src/shared/utils/Analytics';

const googleLogin = () => window.open('/auth/google', '_self');
// const fbLogin = () => window.open('/auth/facebook', '_self');

const Signup = () => {
    const dispatch = useDispatch();
    const [register] = useSignupMutation();
    const [verify] = useLazyVerifyQuery();

    const [signupData, setSignupData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleNavigation = useCustomNavigation();
    const location = useLocation();

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('verify_token');
        const newUser = new URLSearchParams(location.search).get('new_user');

        // if (token && newUser) {
        //     verifyUser(token);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const verifyUser = async (token) => {
        try {
            dispatch(startLoading());
            const res = await verify({ token }).unwrap();
            if (res.statusCode === 1) {
                toast.error(res.message, { toastId: '1' });
                handleNavigation(ROUTES.LOGIN);
            } else {
                const userData = { ...res?.data?.user, data: [] };
                dispatch(updateToken(res?.data?.token));
                dispatch(updateUserDetails(userData));
                toast.success(res.message, { toastId: '2' });
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const goToLogin = () => {
        handleNavigation(ROUTES.LOGIN);
    };

    const handleChange = (e) => {
        setSignupData((state) => ({ ...state, [e.target.id]: e.target.value }));
        setErrors((state) => ({ ...state, [e.target.id]: '' }));
    };

    const handleSubmit = async () => {
        const errors = validateFields(signupData);
        if (Object.keys(errors).length) {
            setErrors({ ...errors });
            return;
        }

        try {
            analytics.sendClickEvent(
                'signup_click',
                ANALYTICS.SCREEN_NAMES.SIGNUP,
                ANALYTICS.ACTIONS.SIGNUP_CLICK
            );
            dispatch(startLoading());
            await register(signupData)
                .unwrap()
                .then((data) => {
                    toast.success(data?.message);
                    handleNavigation(ROUTES.LOGIN, true);
                })
                .catch((err) => toast.error(err?.data?.message));
        } catch (e) {
            toast.error('error registering user');
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title} aria-label={STRINGS.APP_NAME}>
                    {STRINGS.APP_NAME}
                </div>

                <div className={styles.form}>
                    <CustomInput
                        label={LABELS.NAME}
                        onChange={handleChange}
                        error={errors?.name}
                        id={'name'}
                        value={signupData.name}
                        max={32}
                    />
                    <CustomInput
                        label={LABELS.EMAIL}
                        onChange={handleChange}
                        error={errors?.email}
                        id={'email'}
                        value={signupData.email}
                        max={120}
                    />
                    <CustomInput
                        label={LABELS.PASSWORD}
                        onChange={handleChange}
                        error={errors?.password}
                        id={'password'}
                        value={signupData.password}
                        type={'password'}
                        max={12}
                    />
                    <CustomInput
                        label={LABELS.CONFIRM_PASSWORD}
                        onChange={handleChange}
                        error={errors?.confirmPassword}
                        id={'confirmPassword'}
                        value={signupData.confirmPassword}
                        type={'password'}
                        max={12}
                    />
                    <CustomButton
                        className={styles.formSubmit}
                        title={STRINGS.SIGNUP}
                        onClick={handleSubmit}
                    />
                </div>

                <div className={styles.containerHeading}>{STRINGS.LOGIN_WITH}</div>
                <div className={styles.ctas}>
                    <SocialButton
                        className={styles.ctasBtn}
                        icon={GoogleIcon}
                        title={LABELS.GOOGLE}
                        onClick={googleLogin}
                    />
                    {/* <SocialButton icon={FbIcon} title={'Facebook'} onClick={fbLogin} /> */}
                </div>

                <div className={styles.containerFooter}>
                    {STRINGS.ALREADY_HAVE_ACCOUNT}
                    <span className={styles.containerFooterSignup} onClick={goToLogin}>
                        {STRINGS.LOGIN}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Signup);

const validateFields = (data) => {
    let errors = {};
    if (!data.name) {
        errors = { ...errors, name: 'Required' };
    }
    if (!data.email) {
        errors = { ...errors, email: 'Required' };
    } else {
        if (!validateEmail(data.email)) {
            errors = { ...errors, email: 'Invalid Email' };
        }
    }

    if (!data.password) {
        errors = { ...errors, password: 'Required' };
    } else {
        if (!validatePassword(data.password)) {
            errors = {
                ...errors,
                password:
                    'minimum 8 characters, containing at least one uppercase letter, one smallcase letter, one digit, and one special character:'
            };
        }
    }

    if (!data.confirmPassword) {
        errors = { ...errors, confirmPassword: 'Required' };
    }
    if (data.confirmPassword && data.password) {
        if (data.password.localeCompare(data.confirmPassword) !== 0) {
            errors = { ...errors, confirmPassword: 'does not match password' };
        }
    }

    return errors;
};

const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

function validatePassword(password) {
    return passwordRegex.test(password);
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validateEmail(email) {
    return emailRegex.test(email);
}
