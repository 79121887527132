import { useLocation, useNavigate } from 'react-router-dom';

const useCustomNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (route, replace = false, state = {}) => {
        if (location?.pathname !== route) {
            navigate(route, {
                replace: replace,
                state
            });
        }
    };

    return handleNavigation;
};

export default useCustomNavigation;
