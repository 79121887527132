import React from 'react';
import cx from 'classnames';

interface IProps {
    classname?: string;
}

const BetaTag = ({ classname }: IProps) => {
    return (
        <p
            className={cx(
                'bg-green-600',
                'rounded-2xl',
                'py-1 px-2',
                'text-white font-semibold text-xs',
                'tracking-wide',
                'absolute',
                classname
            )}
        >
            {'BETA'}
        </p>
    );
};

export default React.memo(BetaTag);
