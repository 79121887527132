import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const searchApis = createApi({
    reducerPath: 'searchApis',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/search',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        serach: builder.query({
            query: (payload) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `fast`,
                    params: { data: payload.data }
                };
            }
        })
    })
});

export const { useLazySerachQuery } = searchApis;
