export const BDR_EMAIL_PROMPTS = [
    ` Instructions: 
    Do not use signature in the email. Wherever possible, use numbers and examples from the original document.
    divide the email into the following 4 paragraphs.
        In paragraph one, introduce yourself and your company in 15 words.
        In paragraph two, summarize the problem in less than 25 words.
        In paragraph three, summarize how your company solves the problem in less than 25 words.
        In paragraph four, ask for a meeting in less than 15 words.`,
    `Instructions: 
    Do not use signature in the email. Wherever possible, use numbers and examples from the original document.
    divide the email into the following 2 paragraphs.
        In paragraph one, summarize the problems faced by the prospect company in less than 80 words.
        In paragraph two, ask for a meeting in less than 15 words.`,
    `Instructions: 
    Do not use signature in the email. Wherever possible, use numbers and examples from the original document.
    divide the email into the following 2 paragraphs.
        In paragraph one, summarize the benefits derived from the sender company products, in less than 80 words.
        In paragraph two, ask for a meeting in less than 15 words.`
];

export const PROMPT_INSTRUCTIONS = {
    videoScript: `
    Instructions:
    Based on the input document, generate idea for a one-minute video script with a maximum length of 150 words. 
    Wherever possible, use numbers and examples from the original input.`,
    videoScriptSet: `
    Instructions: 
    Based on the input document, Create three video scripts, the three scripts should together cover the entire input document in a series, each with a maximum length of 150 words. 
    The three scripts should together cover the entire input document in a series. 
    Wherever possible, use numbers and examples from the original document.`
};
