const SuggestionItems = (query) => {
  return [
    {
      title: "Bold",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark("bold").run();
      }
    },
    {
      title: "Italic",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark("italic").run();
      }
    },
    {
      title: "Strike",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark("strike").run();
      }
    }    
  ]
    .filter((item) => item.title.toLowerCase())
    .slice(0, 10);
};

export default SuggestionItems;
