import React, { useState } from 'react';
import cx from 'classnames';

//constants
import { ERRORS, LABELS, STRINGS } from 'src/shared/strings';

//components
import { CustomButton, CustomInput } from 'src/widgets';

//styles
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

const AddMemberCta = ({ handleAddMember = async () => {}, className = '', ctaClass = '' }) => {
    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState('');

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setValue('');
    };

    const handleValue = (e) => {
        const value = e?.target?.value;
        setValue(value?.trim());
    };

    const handleAddCta = async () => {
        if (!value) {
            toast.error(ERRORS.EMPTY_INPUT);
            return;
        }
        await handleAddMember(value, handleCloseModal);
    };

    const renderModalCard = () => {
        return (
            <div className={styles.modal} onClick={handleCloseModal}>
                <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.modalContainerHeader}>
                        <div className={styles.modalContainerTitle}>{STRINGS.ADD_MEMBER}</div>
                        <div className={styles.modalContainerClose} onClick={handleCloseModal}>
                            {'X'}
                        </div>
                    </div>
                    <div className={styles.addCard}>
                        <CustomInput value={value} onChange={handleValue} />
                        <CustomButton
                            className={cx(styles.addCta, styles.addCardCta, {
                                [styles.addCardDisabled]: !value
                            })}
                            title={LABELS.ADD}
                            onClick={handleAddCta}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={cx(className, styles.add)}>
            <CustomButton
                className={cx(styles.addCta, ctaClass)}
                title={STRINGS.ADD_MEMBER}
                onClick={handleShowModal}
            />

            {!!showModal && renderModalCard()}
        </div>
    );
};

export default React.memo(AddMemberCta);
