import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//helpers
import { useLazyOauthLinkedinQuery } from 'src/redux/queries/Linkedin';
import { startLoading, stopLoading } from 'src/redux/reducers';

const Linkedin = () => {
    const dispatch = useDispatch();
    const loc = useLocation();

    const [oauthLinkedin] = useLazyOauthLinkedinQuery();
    const handleNavigation = useCustomNavigation();

    const errorToast = () =>
        toast.error('Oops login failed!', {
            toastId: 'loginFailed'
        });

    useEffect(() => {
        handleLinkedinRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLinkedinRedirect = async () => {
        try {
            dispatch(startLoading());
            const code = loc.search?.split('=')[1];
            await oauthLinkedin({ code });

            const linkedinRedirect = localStorage.getItem('linkedin_redirect') || null;
            if (linkedinRedirect) {
                handleNavigation(linkedinRedirect, true);
                localStorage.removeItem('linkedin_redirect');
            }
        } catch (err) {
            errorToast();
        } finally {
            dispatch(stopLoading());
        }
    };

    return <></>;
};

export default React.memo(Linkedin);
