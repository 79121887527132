import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './AddPersona.module.scss';

//widgets
import { CustomInput } from 'src/widgets';
import Icomoon from 'src/assets/Icomoon';

//constants
import { LABELS, PERSONA, STRINGS } from 'src/shared/strings';
import { PERSONA_TONE_OPTIONS } from 'src/shared/constants';

//helpers
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { validateForm } from './validateForm';
import { toast } from 'react-toastify';

//apis
import { useAddPersonaMutation, useUpdatePersonaMutation } from 'src/redux/queries/Personas';

const defaultState = {
    name: '',
    title: '',
    tone: '',
    writingStyle: '',
    personality: '',
    description: ''
};

const AddPersona = ({ data, isOpen, onClose, onSuccess = (persona) => {}, setData }) => {
    const userDetails = useSelector((state) => state.user.userDetails);

    const dispatch = useDispatch();

    const [addNewPersona] = useAddPersonaMutation();
    const [updatePersona] = useUpdatePersonaMutation();

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(defaultState);
    const [errors, setErrors] = useState(defaultState);

    useEffect(() => {
        if (data && Object.entries(data)?.length) {
            let obj = {
                name: data.name,
                title: data.title,
                tone: { label: data.tone, value: data.tone },
                writingStyle: data.writingStyle || data.mainStyle,
                personality: data.personality,
                description: data.description || data.tooltip,
                id: data._id
            };
            setIsEditing(true);
            setFormData({ ...obj });
        }
    }, [data, isOpen]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors({ ...errors, [id]: '' });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const errors = validateForm(formData);
            setErrors({ ...validateForm(formData) });
            if (errors && Object.values(errors).length) {
                return;
            }

            dispatch(startLoading());
            const payload = {
                ...formData,
                tooltip: formData?.description,
                tone: formData?.tone?.value,
                orgId: userDetails?.orgs.find((org) => org.isDefault)?.code || ''
            };

            if (!isEditing) {
                await addNewPersona(payload)
                    .unwrap()
                    .then((data) => {
                        let persona = data?.data?.persona;
                        if (persona) onSuccess(persona);
                    })
                    .catch((err) => {
                        throw err;
                    });
            } else {
                const { id, orgId, ...data } = payload;
                await updatePersona({ payload: data, id })
                    .unwrap()
                    .then((res) => {
                        let persona = res?.data?.persona;
                        if (persona) onSuccess(persona, isEditing);
                    })
                    .catch((err) => {
                        throw err;
                    });
            }
            handleClose();
        } catch (err) {
            console.log('err >>>> ', err);
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleClose = () => {
        setData({});
        setFormData(defaultState);
        setErrors(defaultState);
        onClose(false);
    };

    const renderFields = () => {
        const className = cx(styles.mainField, '');
        return [
            {
                label: PERSONA.NAME,
                placeholder: 'eg. Persuader',
                value: formData.name,
                error: errors?.name,
                id: 'name',
                tooltip: 'Give a short title for this persona. E.g., BDR or Sales Exec'
            },
            {
                label: PERSONA.TITLE,
                placeholder: 'eg. CEO',
                value: formData.title,
                error: errors?.title,
                id: 'title'
            },
            {
                label: PERSONA.TONE,
                placeholder: 'eg. Serious',
                value: formData.tone,
                error: errors?.tone,
                id: 'tone',
                inputType: 'createableSelect',
                selectOptions: PERSONA_TONE_OPTIONS.sort((a, b) => a.value.localeCompare(b.value)),
                placement: 'bottom',
                isMulti: false,
                tooltip: 'What attitude do you want the writer to convey?'
            },

            {
                label: PERSONA.PERSONALITY,
                placeholder: 'eg. friendly, but to the point',
                value: formData.personality,
                error: errors?.personality,
                id: 'personality',
                tooltip: 'What personality do you want the writer to assume?',
                inputType: 'textarea'
            },
            {
                label: PERSONA.STYLE,
                placeholder: `eg. persuasive writing`,
                value: formData.writingStyle,
                error: errors?.writingStyle,
                id: 'writingStyle',
                inputType: 'textarea',
                tooltip:
                    'This is how a writer expresses ideas in their content. Tell us the phrases or terms you want to avoid, your grammar style, punctuation (or lack thereof), reading grade, etc.'
            },
            {
                label: PERSONA.DESCRIPTION,
                placeholder: `eg.Suitable for ...`,
                value: formData.description || formData?.tooltip,
                error: errors?.description,
                id: 'description',
                inputType: 'textarea'
            }
        ].map((field, key) => {
            return (
                <CustomInput
                    key={key}
                    id={field.id}
                    label={field.label}
                    inputClass={styles.input}
                    value={field.value}
                    onChange={handleChange}
                    error={field.error}
                    className={className}
                    inputType={field.inputType}
                    inputProps={{
                        placeholder: field.placeholder
                    }}
                    selectOptions={field.selectOptions}
                    placement={field.placement}
                    isMulti={field.isMulti}
                    tooltipText={field.tooltip}
                />
            );
        });
    };

    return (
        <>
            {isOpen && (
                <div className={styles.overlay}>
                    <div className={styles.content}>
                        <Icomoon
                            className={styles.closeButton}
                            onClick={handleClose}
                            icon={'add'}
                        />

                        <h1 className={styles.title}>
                            {isEditing ? STRINGS.EDIT_CUSTOM_PERSONA : STRINGS.ADD_CUSTOM_PERSONA}
                        </h1>
                        <form onSubmit={handleSubmit}>
                            {renderFields()}

                            <button className={styles.button} type="submit">
                                {isEditing ? LABELS.UPDATE : LABELS.SUBMIT}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(AddPersona);
