import React, { useEffect } from 'react';

const Tnc = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-100 p-6">
            <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>

            <div className="w-8/12 m-auto">
                <div className="container mx-auto px-4 py-8">
                    <p className="mb-4 text-gray-600 font-medium">TERMS OF USE</p>
                    <p className="mb-4">
                        The web services provided by Campaign Like a Pro (the “Site”) is a
                        copyrighted work belonging to Campaign Like a Pro, Inc. (“Company”, “us”,
                        “our”, and “we”). Certain features of the Site may be subject to additional
                        guidelines, terms, or rules, which will be posted on the Site in connection
                        with such features. All such additional terms, guidelines, and rules are
                        incorporated by reference into these Terms.
                    </p>
                    <p className="mb-4">
                        In order to use certain features of the Site, you must register for an
                        account (“Account”) and provide certain information about yourself as
                        prompted by the account registration form. You represent and warrant that:
                        (a) all required registration information you submit is truthful and
                        accurate; (b) you will maintain the accuracy of such information. You may
                        delete your Account at any time, for any reason, by following the
                        instructions on the Site.
                    </p>
                    <p className="mb-4">
                        You are responsible for maintaining the confidentiality of your Account
                        login information and are fully responsible for all activities that occur
                        under your Account. You agree to immediately notify the Company of any
                        unauthorized use, or suspected unauthorized use of your Account or any other
                        breach of security. Company cannot and will not be liable for any loss or
                        damage arising from your failure to comply with the above requirements.
                    </p>
                    <p className="mb-4">
                        Subject to these Terms, Company grants you a non-transferable,
                        non-exclusive, revocable, limited license to use and access the Site solely
                        for your own personal, noncommercial use.
                    </p>
                    <p className="mb-4">
                        The rights granted to you in these Terms are subject to the following
                        restrictions: (a) you shall not license, sell, rent, lease, transfer,
                        assign, distribute, host, or otherwise commercially exploit the Site,
                        whether in whole or in part, or any content displayed on the Site; (b) you
                        shall not modify, make derivative works of, disassemble, reverse compile or
                        reverse engineer any part of the Site; (c) you shall not access the Site in
                        order to build a similar or competitive website, product, or service; and
                        (d) except as expressly stated herein, no part of the Site may be copied,
                        reproduced, distributed, republished, downloaded, displayed, posted or
                        transmitted in any form or by any means. Unless otherwise indicated, any
                        future release, update, or other addition to functionality of the Site shall
                        be subject to these Terms. All copyright and other proprietary notices on
                        the Site (or on any content displayed on the Site) must be retained on all
                        copies thereof.
                    </p>
                    <p className="mb-4">
                        Company reserves the right, at any time, to modify, suspend, or discontinue
                        the Site (in whole or in part) with or without notice to you. You agree that
                        the Company will not be liable to you or to any third party for any
                        modification, suspension, or discontinuation of the Site or any part
                        thereof.
                    </p>
                    <p className="mb-4">
                        Excluding any Customer Content that you may provide (defined below), you
                        acknowledge that all the intellectual property rights, including copyrights,
                        patents, trade marks, and trade secrets, in the Site and its content are
                        owned by Company or Company's suppliers. Neither these Terms (nor your
                        access to the Site) transfers to you or any third party any rights, title or
                        interest in or to such intellectual property rights. Company and its
                        suppliers reserve all rights not granted in these Terms. There are no
                        implied licenses granted under these Terms.
                    </p>
                    <p className="mb-4">
                        If you provide Company with any feedback or suggestions regarding the Site
                        (“Feedback”), you hereby assign to Company all rights in such Feedback and
                        agree that Company shall have the right to use and fully exploit such
                        Feedback and related information in any manner it deems appropriate. Company
                        will treat any Feedback you provide to Company as non-confidential and
                        non-proprietary. You agree that you will not submit to the Company any
                        information or ideas that you consider to be confidential or proprietary.
                    </p>
                    <p className="mb-4">
                        “Customer Content” means any and all information and content that a user
                        submits to, or uses with, the Site. You are solely responsible for your
                        Customer Content. You assume all risks associated with use of your Customer
                        Content, including any reliance on its accuracy, completeness or usefulness
                        by others, or any disclosure of your Customer Content that personally
                        identifies you or any third party. You hereby represent and warrant that
                        your Customer Content does not violate our Acceptable Use Policy. You may
                        not represent or imply to others that your Customer Content is in any way
                        provided, sponsored or endorsed by the Company. Since you alone are
                        responsible for your Customer Content, you may expose yourself to liability
                        if, for example, your Customer Content violates the Acceptable Use Policy.
                        Company is not obligated to backup any Customer Content, and your Customer
                        Content may be deleted at any time without prior notice. You are solely
                        responsible for creating and maintaining your own backup copies of your
                        Customer Content if you desire.
                    </p>
                    <p className="mb-4">
                        You hereby grant to Company a nonexclusive, royalty-free, worldwide license
                        to collect, store, use, transmit and modify your Customer Content solely for
                        the purpose of providing Campaign Like a Pro services to the customer.
                    </p>
                    <p className="mb-4">
                        The following terms constitute our “Acceptable Use Policy”:
                    </p>
                    <ol type="a" className="list-[upper-roman] list-inside mb-4">
                        <li className="my-2">
                            You agree not to use the Site to collect, upload, transmit, display, or
                            distribute any Customer Content (i) that violates any third-party right,
                            including any copyright, trademark, patent, trade secret, moral right,
                            privacy right, right of publicity, or any other intellectual property or
                            proprietary right, (ii) that is unlawful, harassing, abusive, tortious,
                            threatening, harmful, invasive of another’s privacy, vulgar, defamatory,
                            false, intentionally misleading, trade libelous, pornographic, obscene,
                            patently offensive, promotes racism, bigotry, hatred, or physical harm
                            of any kind against any group or individual or is otherwise
                            objectionable, (iii) that is harmful to minors in any way, or (iv) that
                            is in violation of any law, regulation, or obligations or restrictions
                            imposed by any third party.
                        </li>
                        <li>
                            In addition, you agree not to: (i) upload, transmit, or distribute to or
                            through the Site any computer viruses, worms, or any software intended
                            to damage or alter a computer system or data; (ii) send through the Site
                            unsolicited or unauthorized advertising, promotional materials, junk
                            mail, spam, chain letters, pyramid schemes, or any other form of
                            duplicative or unsolicited messages, whether commercial or otherwise;
                            (iii) use the Site to harvest, collect, gather or assemble information
                            or data regarding other users, including e-mail addresses, without their
                            consent; (iv) interfere with, disrupt, or create an undue burden on
                            servers or networks connected to the Site, or violate the regulations,
                            policies or procedures of such networks; (v) attempt to gain
                            unauthorized access to the Site (or to other computer systems or
                            networks connected to or used together with the Site), whether through
                            password mining or any other means; (vi) harass or interfere with any
                            other user’s use and enjoyment of the Site; or (vi) use software or
                            automated agents or scripts to produce multiple accounts on the Site, or
                            to generate automated searches, requests, or queries to (or to strip,
                            scrape, or mine data from) the Site (provided, however, that we
                            conditionally grant to the operators of public search engines revocable
                            permission to use spiders to copy materials from the Site for the sole
                            purpose of and solely to the extent necessary for creating publicly
                            available searchable indices of the materials, but not caches or
                            archives of such materials, subject to the parameters set forth in our
                            robots.txt file).
                        </li>
                    </ol>

                    <p className="mb-4">
                        We reserve the right (but have no obligation) to review, refuse and/or
                        remove any Customer Content in our sole discretion, and to investigate
                        and/or take appropriate action against you in our sole discretion if you
                        violate the Acceptable Use Policy or any other provision of these Terms or
                        otherwise create liability for us or any other person. Such action may
                        include removing or modifying your Customer Content, terminating your
                        Account, and/or reporting you to law enforcement authorities.
                    </p>
                    <p className="mb-4">
                        You agree to indemnify and hold Company (and its officers, employees, and
                        agents) harmless, including costs and attorneys’ fees, from any claim or
                        demand made by any third party due to or arising out of (a) your use of the
                        Site, (b) your violation of these Terms, (c) your violation of applicable
                        laws or regulations or (d) your Customer Content. Company reserves the
                        right, at your expense, to assume the exclusive defense and control of any
                        matter for which you are required to indemnify us, and you agree to
                        cooperate with our defense of these claims. You agree not to settle any
                        matter without the prior written consent of the Company. Company will use
                        reasonable efforts to notify you of any such claim, action or proceeding
                        upon becoming aware of it.
                    </p>
                    <p className="mb-4">
                        THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY
                        (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS
                        OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
                        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR
                        SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL
                        BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR
                        WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE,
                        LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO
                        THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO 90 DAYS FROM THE
                        DATE OF FIRST USE.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                        WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS
                        DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
                        LIMITATION MAY NOT APPLY TO YOU.
                    </p>
                    <p className="mb-4">
                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR
                        SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST
                        DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT,
                        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                        FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE
                        SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU
                        WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM,
                        OR LOSS OF DATA RESULTING THEREFROM.TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO
                        YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE
                        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                        LIMITED TO A MAXIMUM OF FIFTY US DOLLARS. THE EXISTENCE OF MORE THAN ONE
                        CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
                        LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.SOME
                        JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
                        MAY NOT APPLY TO YOU.
                    </p>
                    <p className="mb-4">
                        Subject to this Section, these Terms will remain in full force and effect
                        while you use the Site. We may suspend or terminate your rights to use the
                        Site (including your Account) at any time for any reason at our sole
                        discretion, including for any use of the Site in violation of these Terms.
                        Upon termination of your rights under these Terms, your Account and right to
                        access and use the Site will terminate immediately. You understand that any
                        termination of your Account may involve deletion of your Customer Content
                        associated with your Account from our live databases. Company will not have
                        any liability whatsoever to you for any termination of your rights under
                        these Terms, including for termination of your Account or deletion of your
                        Customer Content.
                    </p>
                    <p className="mb-4">
                        These Terms are subject to occasional revision, and if we make any changes,
                        such changes will be published on this site with the latest date for which
                        such changes were made.
                    </p>
                    <p className="mb-4">
                        The Site may be subject to U.S. export control laws and may be subject to
                        export or import regulations in other countries. You agree not to export,
                        reexport, or transfer, directly or indirectly, any U.S. technical data
                        acquired from Company, or any products utilizing such data, in violation of
                        the United States export laws or regulations.
                    </p>
                    <p className="mb-4">
                        If you are a California resident, you may report complaints to the Complaint
                        Assistance Unit of the Division of Consumer Product of the California
                        Department of Consumer Affairs by contacting them in writing at 400 R
                        Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
                    </p>
                    <p className="mb-4">
                        The communications between you and Company use electronic means, whether you
                        use the Site or send us emails, or whether Company posts notices on the Site
                        or communicates with you via email. For contractual purposes, you (a)
                        consent to receive communications from Company in an electronic form; and
                        (b) agree that all terms and conditions, agreements, notices, disclosures,
                        and other communications that Company provides to you electronically satisfy
                        any legal requirement that such communications would satisfy if it were be
                        in a hardcopy writing. The foregoing does not affect your non-waivable
                        rights.
                    </p>
                    <p className="mb-4">
                        These Terms constitute the entire agreement between you and us regarding the
                        use of the Site. Our failure to exercise or enforce any right or provision
                        of these Terms shall not operate as a waiver of such right or provision. The
                        section titles in these Terms are for convenience only and have no legal or
                        contractual effect. The word “including” means “including without
                        limitation”. If any provision of these Terms is, for any reason, held to be
                        invalid or unenforceable, the other provisions of these Terms will be
                        unimpaired and the invalid or unenforceable provision will be deemed
                        modified so that it is valid and enforceable to the maximum extent permitted
                        by law. Your relationship to Company is that of an independent contractor,
                        and neither party is an agent or partner of the other. These Terms, and your
                        rights and obligations herein, may not be assigned, subcontracted,
                        delegated, or otherwise transferred by you without Company's prior written
                        consent, and any attempted assignment, subcontract, delegation, or transfer
                        in violation of the foregoing will be null and void. Company may freely
                        assign these Terms. The terms and conditions set forth in these Terms shall
                        be binding upon assignees.
                    </p>
                    <p className="mb-4">
                        Copyright &copy; 2024 Campaign Like a Pro, Inc. All rights reserved. All
                        trademarks, logos and service marks (“Marks”) displayed on the Site are our
                        property or the property of other third parties. You are not permitted to
                        use these Marks without our prior written consent or the consent of such
                        third party which may own the Marks.
                    </p>
                    <p className="my-4">Last revised: March 13, 2024</p>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Tnc);
