import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

export const audienceApi = createApi({
    reducerPath: 'audienceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/audience',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAudineces: builder.query({
            query: () => ({
                method: 'Get',
                headers: { ...headers },
                url: '/all'
            })
        }),
        addAudience: builder.mutation({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: '/add',
                body: payload
            })
        }),
        updateAudience: builder.mutation({
            query: ({ payload, id }) => ({
                method: 'PATCH',
                headers: { ...headers },
                url: `/${id}`,
                body: payload
            })
        }),
        deleteAudience: builder.query({
            query: (id) => ({
                method: 'Delete',
                headers: { ...headers },
                url: `/${id}`
            })
        }),
        getAudienceSegments: builder.query({
            query: () => ({
                method: 'Get',
                headers: { ...headers },
                url: '/taxonomy'
            })
        })
    })
});

export const {
    useLazyGetAudinecesQuery,
    useAddAudienceMutation,
    useUpdateAudienceMutation,
    useLazyDeleteAudienceQuery,
    useLazyGetAudienceSegmentsQuery
} = audienceApi;
