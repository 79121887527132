import React, { useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//constants
import { CONTENT_FILE_TYPES, PROCESSED_FILE } from 'src/shared/constants';
import { STRINGS } from 'src/shared/strings';

function Accordion({ title, count, data }) {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState('');

    const toggleAccordion = () => {
        !!count && setIsOpen(!isOpen);
    };

    const dataValue = (item) => {
        switch (item.type) {
            case CONTENT_FILE_TYPES.PDF.value:
                return <a href={item?.fileLink}>{item?.fileName}</a>;
            case CONTENT_FILE_TYPES.DOCX.value:
                return <a href={item?.fileLink}>{item?.fileName}</a>;
            case CONTENT_FILE_TYPES.TEXT.value:
                return (
                    <span className={styles.textcontent} onClick={() => setShowModal(item?.text)}>
                        {item?.text}
                    </span>
                );
            case CONTENT_FILE_TYPES.URL.value:
                return <a href={item?.url}>{item?.url}</a>;
            default:
                return '';
        }
    };

    const contentFieldName = (item) => {
        switch (item.type) {
            case CONTENT_FILE_TYPES.PDF.value:
                return <span>{CONTENT_FILE_TYPES.PDF.label}</span>;
            case CONTENT_FILE_TYPES.DOCX.value:
                return <span>{CONTENT_FILE_TYPES.DOCX.label}</span>;
            case CONTENT_FILE_TYPES.TEXT.value:
                return <span>{STRINGS.TEXT}</span>;
            case CONTENT_FILE_TYPES.URL.value:
                return <span>{CONTENT_FILE_TYPES.URL.label}</span>;
            default:
                return '';
        }
    };

    const processedFileValue = (item) => (
        <a href={item?.processedFileLink}>{item?.processedFileName}</a>
    );

    const renderModal = () => (
        <div className={styles.modal}>
            <div className={styles.modalContainer}>
                <div className={styles.modalContainerClose} onClick={() => setShowModal('')}>
                    {'X'}
                </div>
                <div className={styles.modalContainerText}>{showModal}</div>
            </div>
        </div>
    );

    const renderList = () => {
        if (!data) return <div className={styles.listEmpty}>{STRINGS.NO_DATA_FOUND}</div>;
        return (
            <div className={styles.list}>
                <div className={cx(styles.listItem, styles.listHead)}>
                    <div className={styles.listItemCol}>{STRINGS.SERIAL}</div>
                    <div className={styles.listItemCol}>{contentFieldName(data[0])}</div>
                    <div className={styles.listItemCol}>{PROCESSED_FILE.label}</div>
                    <div className={styles.listItemCol}>{STRINGS.DATE}</div>
                </div>
                {data.map((item, idx) => {
                    return (
                        <div key={idx} className={styles.listItem}>
                            <div className={styles.listItemCol}>{idx + 1}</div>
                            <div className={styles.listItemCol}>{dataValue(item)}</div>
                            <div className={styles.listItemCol}>{processedFileValue(item)}</div>
                            <div className={styles.listItemCol}>
                                {new Date(item.createdAt).toLocaleString()}
                            </div>
                        </div>
                    );
                })}
                {!!showModal && renderModal()}
            </div>
        );
    };

    return (
        <div className={styles.accordion}>
            <div
                className={cx(styles.accordionHeader, { [styles.accordionHeaderOpen]: isOpen })}
                onClick={toggleAccordion}
            >
                {title}
                <span className={styles.accordionCount}>{count}</span>
            </div>
            {isOpen && <div className={styles.accordionBody}>{renderList()}</div>}
        </div>
    );
}

export default React.memo(Accordion);
