import React, { useState } from 'react';

//widgets
import { SocialButton } from '../widgets';

//styles
import styles from './styles.module.scss';

//assets
import GoogleIcon from 'src/assets/icons/google.svg';
// import FbIcon from 'src/assets/icons/facebook.svg';

//constants
import { LABELS, STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants';

//components
import { CustomButton, CustomInput } from 'src/widgets';
import { toast } from 'react-toastify';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { useDispatch } from 'react-redux';

//apis
import { useLoginMutation } from 'src/redux/queries';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { updateToken, updateUserDetails } from 'src/redux/reducers/userReducer';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

const googleLogin = () => window.open('/auth/google', '_self');
// const fbLogin = () => window.open('/auth/facebook', '_self');

const forgotCtaCss = 'my-2 text-sm text-slate-500 hover:text-slate-900 cursor-pointer';

const Login = () => {
    const dispatch = useDispatch();
    const [login] = useLoginMutation();
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({ email: '', password: '' });

    const handleNavigation = useCustomNavigation();

    const goToSignup = () => {
        handleNavigation(ROUTES.SIGNUP);
    };

    const goToForgotPassword = () => handleNavigation(ROUTES.FORGOT_PASSWORD);

    const handleChange = (e) => {
        setLoginData((state) => ({ ...state, [e.target.id]: e.target.value }));
    };

    const handleSubmit = async () => {
        const errors = validateFields(loginData);
        if (Object.keys(errors).length) {
            setErrors({ ...errors });
            return;
        }

        try {
            analytics.sendClickEvent(
                'login_click',
                ANALYTICS.SCREEN_NAMES.LOGIN,
                ANALYTICS.ACTIONS.LOGIN_CLICK
            );
            dispatch(startLoading());
            await login(loginData)
                .unwrap()
                .then((data) => {
                    const userData = { ...data?.data?.user, data: [] };
                    dispatch(updateToken(data?.data?.token));
                    dispatch(updateUserDetails(userData));
                    toast.success(data?.message);
                })
                .catch((err) => toast.error(err?.data?.message));
        } catch (e) {
            toast.error('error logging in user');
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title} aria-label={STRINGS.APP_NAME}>
                    {STRINGS.APP_NAME}
                </div>

                <div className={styles.form}>
                    <CustomInput
                        label={LABELS.EMAIL}
                        onChange={handleChange}
                        error={errors?.email}
                        id={'email'}
                        value={loginData.email}
                        max={120}
                    />
                    <CustomInput
                        label={LABELS.PASSWORD}
                        onChange={handleChange}
                        error={errors?.password}
                        id={'password'}
                        value={loginData.password}
                        type="password"
                        max={12}
                    />

                    <div className={forgotCtaCss} onClick={goToForgotPassword}>
                        {STRINGS.FORGOT_PASSWORD}?
                    </div>

                    <CustomButton
                        className={styles.formSubmit}
                        title={STRINGS.LOGIN}
                        onClick={handleSubmit}
                    />
                </div>

                <div className={styles.containerHeading}>{STRINGS.LOGIN_WITH}</div>
                <div className={styles.ctas}>
                    <SocialButton
                        className={styles.ctasBtn}
                        icon={GoogleIcon}
                        title={LABELS.GOOGLE}
                        onClick={googleLogin}
                    />
                    {/* <SocialButton icon={FbIcon} title={'Facebook'} onClick={fbLogin} /> */}
                </div>

                <div className={styles.containerFooter}>
                    {STRINGS.DONT_HAVE_ACCOUNT}
                    <span className={styles.containerFooterSignup} onClick={goToSignup}>
                        {STRINGS.SIGNUP}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Login);

const validateFields = (data) => {
    let errors = {};
    if (data.email === '') {
        errors = { ...errors, email: 'Required' };
    }
    if (data.password === '') {
        errors = { ...errors, password: 'Required' };
    }

    return errors;
};
