import React, { useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph } from 'docx';

//helpers
import { getContentTypeLabel } from 'src/shared/utils/helpers';
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { formatMarkdown, removeHTMLTags } from 'src/shared/utils';

//styles
import styles from './styles.module.scss';

//widgets
import { CustomButton } from 'src/widgets';

//constants
import { STRINGS } from 'src/shared/strings';
import { CLAP_SESSION, PROCESSED_FILE, ROUTES } from 'src/shared/constants';

//api
import { useReadAndSaveFileMutation } from 'src/redux/queries';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

const fontFamily = { fontFamily: ' calibri' };

const customStyles = {
    heading: {
        fontFamily: 'calibri',
        fontSize: 32,
        textAlign: 'center',
        ...fontFamily
    },
    row: {
        marginTop: 32
    },
    subHeading: {
        fontFamily: 'calibri',
        fontWeight: 600,
        fontSize: 24,
        ...fontFamily
    },
    text: {
        textAlign: 'justify',
        marginTop: 12,
        fontSize: 20,
        ...fontFamily
    }
};

const PreviewDoc = ({ data = [], title = '', campaignId = '', isComplete = false }) => {
    useEffect(() => {
        document.title = 'Campaign Like a Pro - Download Campaign Content';
    }, []);

    const [saveFile] = useReadAndSaveFileMutation();
    const handleNavigation = useCustomNavigation();
    const dispatch = useDispatch();

    // data = data.filter((choice) => choice?.accepted);

    const generate = () => {
        try {
            analytics.sendClickEvent(
                'download_doc_click',
                ANALYTICS.SCREEN_NAMES.DOC_CREATER_VIEW,
                ANALYTICS.ACTIONS.DOWNLOAD_DOC_CLICK
            );
            const doc = new Document({
                sections: [
                    {
                        children: [createPageHeading(title), ...processDataForPdf(data)]
                    }
                ]
            });
            Packer.toBlob(doc).then(async (blob) => {
                // saveAs(blob, 'CopyKitAIWizard.docx');
                dispatch(startLoading());
                const clapSession = sessionStorage.getItem(CLAP_SESSION);
                await saveFile({
                    fileSession: clapSession,
                    type: PROCESSED_FILE.value,
                    fileName: `CopyKitAIWizard.docx`,
                    file: new File(
                        [blob],
                        `processed_${clapSession || campaignId?.slice(-5)}.docx`,
                        {
                            type: 'application/msword',
                            lastModified: Date.now()
                        }
                    ),
                    campaignId
                }).then(() => {
                    sessionStorage.clear();
                    handleNavigation(ROUTES.HOME);
                });
            });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderList = (list) => {
        return (
            <ul className={styles.list}>
                {list?.choices?.map((item, idx) => {
                    return (
                        <div className={styles.containerDataItem} key={idx}>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: formatMarkdown(item)
                                }}
                            />
                        </div>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className={styles.wrapper}>
            {!!data?.length ? (
                <>
                    <div className={styles.button}>
                        <CustomButton title={STRINGS.ACCEPT} onClick={generate} />
                    </div>

                    <div className={styles.container} id={`downloadDoc`}>
                        <h2 style={customStyles.heading}>{title}</h2>
                        {data.map((target, targetKey) => {
                            return (
                                <div key={targetKey}>
                                    <p className="border-t-2 pt-1 text-xl font-bold drop-shadow-2xl text-slate-800">
                                        {target?.audience?.label || target?.audience?.name}
                                    </p>
                                    {target?.choices.map((choice, key) => {
                                        return (
                                            <div
                                                key={key}
                                                className={styles.containerData}
                                                style={customStyles.row}
                                            >
                                                <div
                                                    className={styles.containerDataTitle}
                                                    style={customStyles.subHeading}
                                                >
                                                    {getContentTypeLabel(choice.type)}
                                                </div>
                                                <div className={styles.containerDataText}>
                                                    {choice?.choices.length > 1
                                                        ? renderList(choice)
                                                        : choice?.choices?.map((item, idx) => (
                                                              <div
                                                                  className={
                                                                      styles.containerDataItem
                                                                  }
                                                                  key={idx}
                                                              >
                                                                  <span
                                                                      dangerouslySetInnerHTML={{
                                                                          __html: formatMarkdown(
                                                                              item
                                                                          )
                                                                      }}
                                                                  />
                                                              </div>
                                                          ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles.button}>
                        <CustomButton title={STRINGS.ACCEPT} onClick={generate} />
                    </div>
                </>
            ) : (
                <h2 className={styles.empty}>Oops! it seems you haven't accepted any content.</h2>
            )}
        </div>
    );
};

export default React.memo(PreviewDoc);

const createPageHeading = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading1',
        spacing: {
            after: 400,
            before: 200
        },
        alignment: 'center'
    });
};

const createParagraph = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'title'
    });
};

const createHeading = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading2',
        spacing: {
            after: 200,
            before: 200
        }
    });
};

const createHeadingCenter = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading1',
        spacing: {
            after: 200,
            before: 500
        },
        alignment: 'center'
    });
};

const createTweets = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'title',
        bullet: {
            level: 0
        },
        spacing: {
            before: 320
        }
    });
};

const processDataForPdf = (data) => {
    return data
        .map((target) => {
            let res = [];
            res.push(createHeadingCenter(target?.audience?.label));
            res.push(
                ...target?.choices
                    .map((choice, key) => {
                        let arr = [];
                        arr.push(createHeading(getContentTypeLabel(choice?.type)));
                        choice?.choices?.forEach((item, idx) => {
                            const paras = item?.split('\n');
                            paras?.forEach((para, key) => {
                                if (key === 0 && choice?.choices.length > 1) {
                                    arr.push(createTweets(para));
                                } else {
                                    arr.push(createParagraph(para));
                                }
                            });
                        });
                        return arr;
                    })
                    .reduce((prev, curr) => prev.concat(curr), [])
            );
            return res;
        })
        .reduce((prev, curr) => prev.concat(curr), []);
};
