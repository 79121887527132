import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const linkedinApi = createApi({
    reducerPath: 'linkedinApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/linkedin',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getLindekdinData: builder.query({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/getLinkedInProfile`,
                    body: payload
                };
            }
        }),
        initLinkedin: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/`
                };
            }
        }),
        oauthLinkedin: builder.query({
            query: ({ code }) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/oauth-callback`,
                    params: { code }
                };
            }
        }),
        linkedinUserInfo: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/info`
                };
            }
        }),
        linkedinLogout: builder.mutation({
            query: () => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/logout`
                };
            }
        }),
        linkedinShare: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/share`,
                    body: payload
                };
            }
        })
    })
});

export const {
    useLazyGetLindekdinDataQuery,
    useLazyInitLinkedinQuery,
    useLazyOauthLinkedinQuery,
    useLazyLinkedinUserInfoQuery,
    useLinkedinLogoutMutation,
    useLinkedinShareMutation
} = linkedinApi;
