export const cssStyles = {
    overlay: 'fixed top-0 left-0 w-full h-full bg-black opacity-20 z-40',
    container: `fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                w-96 max-w-md bg-white p-6 rounded-md shadow-md overflow-y-auto z-50`,
    close: `absolute top-7 right-8 flex justify-end`,
    closeIcon: 'text-gray-500 hover:text-gray-700 w-4 h-4 cursor-pointer',
    title: 'text-2xl font-bold mr-2',
    input: 'w-full border-2 p-2 rounded-md focus:outline-none focus:border-blue-200',
    contact: 'flex items-center border-b py-2 mr-2',
    contactName: 'text-md font-medium',
    contactEmail: 'text-sm text-gray-500',
    checkbox: 'rounded-md flex items-center justify-center mr-4 w-5 h-5 cursor-pointer border-2',
    checked: 'bg-blue-500 border-blue-500',
    tick: 'text-white w-3 h-3',
    cta: 'bg-white pt-6 text-center',
    ctaBtn: 'w-28 bg-blue-500 text-white py-2 px-4 rounded-md',
    empty: 'text-lg text-gray-500 fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3',
    loader: 'fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3 ',
    send: 'w-6 h-6'
};
