import { createSlice } from '@reduxjs/toolkit';

interface IState {
    segments: { label: string; value: string }[];
}

const initialState = {
    segments: []
} as IState;

const taxonomySlice = createSlice({
    name: 'taxonomy',
    initialState,
    reducers: {
        updateSegments: (state, action) => {
            return {
                ...state,
                segments: action.payload?.map((segment: string) => ({
                    label: segment,
                    value: segment
                }))
            };
        }
    }
});

export const { updateSegments } = taxonomySlice.actions;

export default taxonomySlice.reducer;
