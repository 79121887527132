import React, { useState } from 'react';
import cx from 'classnames';

//helpers
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { useLocation } from 'react-router-dom';

//constants
import { ICON_HUBSPOT, ICON_LINKEDIN } from 'src/shared/constants/icons';

//widgets
import { ContactList } from 'src/components/ContactList';
import { CustomButton } from 'src/widgets';

//apis
import { useLinkedinShareMutation } from 'src/redux/queries/Linkedin';
import { toast } from 'react-toastify';

interface IProps {
    data: string;
    type: string;
}

const LINKEDIN_ICON_CSS = cx(
    'w-[20px] h-[20px] cursor-pointer',
    ICON_LINKEDIN,
    'pl-[2px] py-[1px] mx-3',
    'text-md text-white',
    'bg-[#0077B5]',
    'rounded'
);

const openUrl = (url: string) => window.open(url, '_self');

const ShareContent = ({ data, type }: IProps) => {
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.config);

    const [showContactList, setShowContactList] = useState(false);
    const [showLinkedinPreview, setShowLinkedinPreview] = useState(false);

    const loc = useLocation();

    const [sendPost] = useLinkedinShareMutation();

    const renderHubspotIcon = () => {
        if (!config.isHubspotEnabled) return null;
        return (
            <div
                className={cx('w-[20px] h-[20px] cursor-pointer', ICON_HUBSPOT, 'text-xl')}
                onClick={() => {
                    setShowContactList(true);
                }}
            />
        );
    };

    const renderLinkedinIcon = () => {
        const handleLinkedinClick = () => setShowLinkedinPreview(true);

        return <div className={LINKEDIN_ICON_CSS} onClick={handleLinkedinClick} />;
    };

    const LinkedinSharePreview = () => {
        document.body.style.overflow = 'hidden';
        const fomattedContent = data.replaceAll('<br />', '\n').replaceAll(/<[^>]+>/gi, '');

        const sharePost = async () => {
            try {
                dispatch(startLoading());
                await sendPost({ content: fomattedContent })
                    .unwrap()
                    .then((res) => {
                        if (res?.loginUrl) {
                            localStorage.setItem('linkedin_redirect', loc.pathname);
                            openUrl(res.loginUrl);
                            return;
                        } else {
                            toast.success(res.message);
                            closePreview();
                        }
                    })
                    .catch((err) => toast.error('Failed to share on linkedin!'));
            } catch (error) {
            } finally {
                dispatch(stopLoading());
            }
        };

        const closePreview = () => {
            document.body.style.overflow = '';
            setShowLinkedinPreview(false);
        };

        return (
            <div
                className={cx(
                    'fixed inset-0 z-40',
                    'bg-[rgba(0,0,0,0.1)]',
                    'flex items-center justify-center'
                )}
            >
                <div
                    className={cx(
                        'bg-white',
                        'w-[480px] h-fit min-h-[200px]',
                        'p-5',
                        'rounded-md shadow'
                    )}
                >
                    <div className={cx('mx-0 mb-3 scale-150', LINKEDIN_ICON_CSS)} />

                    <p className={cx('mb-4 whitespace-pre-wrap')}>{fomattedContent}</p>

                    <div className={cx('flex justify-end')}>
                        <CustomButton
                            className={'mx-2 h-[32px]'}
                            title={'Cancel'}
                            onClick={closePreview}
                        />
                        <CustomButton
                            className={'mx-2 h-[32px]'}
                            title={'Share'}
                            onClick={sharePost}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('flex')}>
            {renderHubspotIcon()}
            {renderLinkedinIcon()}
            {showContactList && (
                <ContactList
                    handleClose={() => setShowContactList(false)}
                    data={data}
                    type={type}
                />
            )}
            {showLinkedinPreview && <LinkedinSharePreview />}
        </div>
    );
};

export default React.memo(ShareContent);
